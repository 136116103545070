import callApi from 'services';

export default {
    getLocationList() {
        return callApi('locationTypes', 'GET');
    },
    addLocationSubtype(idLocation, locationSubtype) {
        return callApi(`locationTypes/${idLocation}/subtypes`, 'POST', JSON.stringify(locationSubtype));
    },
    editLocationSubtype(locationSubtype) {
        return callApi(`locationSubtypes/${locationSubtype.id}`, 'PUT', JSON.stringify(locationSubtype));
    },
    deleteLocationSubtype(id) {
        return callApi(`locationSubtypes/${id}`, 'DELETE');
    }
};