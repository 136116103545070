import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

class AutocompletionWithoutListing extends Component {
    constructor(props) {
        super(props);

        /**
         * value: champ de l'input
         * suggestions: liste déroulante à afficher
         * selectedValues: valeurs sélectionnées par l'utilisateur
         */
        this.state = {
            value: this.props.value || '',
            suggestions: [],
        };
    }

    renderSuggestion = suggestion => (
        <div>
            {this.props.toShow !== 'fullname' ? suggestion[this.props.toShow] : (suggestion.firstname + ' ' + suggestion.lastname)}
        </div>
    );

    getSuggestionValue = suggestion => this.props.toShow !== 'fullname' ? suggestion[this.props.toShow] : (suggestion.firstname + ' ' + suggestion.lastname);

    /**
     * Récupération des suggestions de la liste déroulante
     * lorsque l'utilisateur entre une valeur
     */
    getSuggestions = value => {
        if (this.props.suggestions) {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
            if (inputLength === 0) {
                return [];
            } else {
                let suggestions = this.props.suggestions.filter(lang => {
                    const value = this.props.toShow !== 'fullname' ? lang[this.props.toShow] : (lang.firstname + ' ' + lang.lastname);
                    return value && value.toLowerCase().includes(inputValue)
                });
                if (suggestions.length > 9) {
                    return suggestions.slice(0,10);
                }
                return suggestions;
            }
        } else {
            return [];
        }
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    /**
     * Quand l'utilisateur clique sur une suggestion de la liste
     */
    onSuggestionSelected = (event, { suggestion }) => {
        !this.props.keepSelection && this.setState({
            value: '',
        });
        this.props.onSelect(suggestion);
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        const inputProps = {
            value,
            onChange: this.onChange
        };
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />
        )
    }
}

export default AutocompletionWithoutListing;
