import React, { Component } from 'react';
import I18n from '../../resources/i18n/I18n';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModalDelete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    deleteLabel = () => {
        this.toggleModal();
        this.props.deleteLabel();
    };

    toggleModal = () => {
        this.props.toggleModal();
    };

    render() {
        return (
            <div>
                <Modal show={this.props.showModal} onHide={this.toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{I18n.t('DeleteConfirmTitle')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.props.title ?
                                this.props.title :
                                I18n.t('DeleteConfirmText')
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-back" onClick={this.toggleModal}>
                            {I18n.t('ModalConfirmCancel')}
                        </Button>
                        <Button className="primary" onClick={this.deleteLabel}>
                            {I18n.t('ModalConfirmOk')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}


export default ModalDelete;
