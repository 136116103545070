import React, {Component} from "react";
import AutocompletionWithoutListing from "./AutocompletionWithoutListing";
import FontAwesome from "react-fontawesome";
import I18n from "i18n-js";

class CustomList extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.addItem = this.addItem.bind(this)
    }

    handleChange = (items) => {
        this.props.handleChange(this.props.type, items)
    }

    removeItem = (itemToRemove) => {
        let items = this.props.items.filter((item) => item !== itemToRemove);
        this.handleChange(items);
    }

    addItem = (itemToAdd) => {
        let items = [...this.props.items];
        items.push(itemToAdd);
        this.handleChange(items);
    }

    renderItem = (item) => {
        const toShow = this.props.toShow !== 'fullname' ? item[this.props.toShow] : (item.firstname + ' ' + item.lastname);
        return (
            this.props.editMode ?
                (<div key={item.id} className="configuration container d-flex justify-content-between align-items-center">
                    <span className="category-subtitle">{toShow}</span>
                    {this.props.editMode && <div className="d-flex">
                        <div className="action-button small" onClick={this.removeItem.bind(this, item)}>
                            <FontAwesome name="trash" />
                        </div>
                    </div>}
                </div>) : (<div key={item.id} className="category-subtitle">{toShow}</div>)
        )
    }

    render() {
        const items = this.props.items;
        const listItems = items.map(item => this.renderItem(item));
        const alreadySelectedItemsId = items.map(item => item.id);
        const addableItems= this.props.selectables.filter(item => {
                return !alreadySelectedItemsId.includes(item.id);
        });
        return (
            <div>
                {items.length ? listItems : <p style={{color: `${this.props.type === 'agencies' ? "blue" : "red"}`}} >
                    {I18n.t('UserConfigurationNo'+this.props.type)}
                </p>}
                { this.props.editMode && <AutocompletionWithoutListing suggestions={addableItems}
                                                                       toShow={this.props.toShow}
                                                                       onSelect={this.addItem}
                                                                       keepSelection={false}/>}
            </div>
        )

     }

}

export default CustomList;
