import React, { Component } from 'react';
import SimplePieChart from './SimplePieChart';
import WrapperLoader from '../loader/WrapperLoader';

class CustomPieChart extends Component {
    render() {
        const { data, title, subtitle, loading } = this.props;

        return (
            <WrapperLoader loading={loading}>
                <div className="dashboard-tile">
                    <div className="legend">
                        <div className="bold">{title}</div>
                        <div>{subtitle}</div>
                    </div>
                    <SimplePieChart data={data} />
                </div>
            </WrapperLoader>
        )
    }
}

export default CustomPieChart;