import React, {Component} from 'react';
import {ResponsiveContainer, PieChart, Pie, Tooltip, Cell, Legend} from 'recharts';
import CONSTANTS from '../../utils/constants';
import CustomTooltip from './CustomTooltip';

class SimplePieChart extends Component {

    render() {
        const { data } = this.props;

        return (
            <div className="pie-chart">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="count"
                            innerRadius={60}
                            outerRadius={80}
                            paddingAngle={0}
                        >
                            {
                                data.map((entry, index) => <Cell key={index} fill={CONSTANTS.COLORS[index % CONSTANTS.COLORS.length]}/>)
                            }
                        </Pie>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend/>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

export default SimplePieChart;