import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from '../../resources/i18n/I18n';
import LocationApi from '../../services/locationApi';
import { fetchLocationsSuccess } from 'store/actions/resourceActions';
import LabelEditionTree from './LabelEditionTree';
import CustomButton from 'components/buttons/CustomButton';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router-dom';

class LocationEdition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        if (this.props.resources.locations.length === 0) {
            this.getLocations();
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    getLocations = () => {
        LocationApi.getLocationList().then((response) =>
            this.props.dispatch(fetchLocationsSuccess(response))
        )
    };

    deleteLocation = (location) => {
        this.setState({ error: null });
        LocationApi.deleteLocationSubtype(location.id).then(() =>
            //Récupération des types mis à jour
            this.getLocations()
        ).catch(() =>
            this.setState({ error: I18n.t('ErrorDelete') })
        )
    };

    editLocation = (location) => {
        LocationApi.editLocationSubtype(location).then(() =>
            //Récupération des types mis à jour
            this.getLocations()
        )
    };

    createLocation = (item) => {
        LocationApi.addLocationSubtype(item.idParent, { label: item.label }).then(() =>
            //Récupération des types mis à jour
            this.getLocations()
        )
    };

    render() {
        const { locations } = this.props.resources;

        return (
            <div className="container-fluid">
                <LabelEditionTree items={locations}
                    deleteLabel={this.deleteLocation}
                    editLabel={this.editLocation}
                    createLabel={this.createLocation}
                    error={this.state.error} />
                <div className="row justify-content-center p-4">
                    <CustomButton onClick={() => this.goBack()} icon={<FontAwesome name="arrow-left" />} small secondary />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        resources: state.resourceReducer
    };
};

export default connect(mapStateToProps)(withRouter(LocationEdition));