import React, { Component } from "react";
import UserApi from 'services/userApi';
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EstateUsersEditionTableRow from "./EstateUsersEditionTableRow";
import PatrimonyApi from "../../../services/patrimonyApi";
import FontAwesome from "react-fontawesome";
import Pagination from "../../pagination/Pagination";
import ModalImportCsv from "./ModalImportCsv";
import { errorToast, successToast, warningToast } from "../../../utils/toast";
import I18n from "i18n-js";
import Loader from "../../loader/Loader";
import fileDownload from 'js-file-download';
import EstateUserFilters from "../../filters/EstateUserFilters";

class EstateUsersEditionTable extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            users: [],
            agencies: [],
            managers: [],
            currentPage: 0,
            pageCount: undefined,
            searchValue: null,
            syncing: true,
            showModalCsv: false,
            importType: null,
            sortBy: null,
            filterList: {},
        };
    }

    componentDidMount() {
        this.fetchUsers();
        this.getAgencies();
        this.getManagers();
    }

    fetchUsers = () => {
        UserApi.getUsersList({
            ...this.state.filterList,
            groupId: this.props.groupId,
            currentPage: this.state.currentPage,
            searchValue: this.state.searchValue,
            sortBy: this.state.sortBy
        }).then(users => {
            this.setState({
                syncing: false,
                users: users.userList,
                pageCount: users.metadata.pageCount,
            });
        });
    }

    getAgencies = () => {
        PatrimonyApi.getPatrimonyList({ groupId: this.props.groupId }).then(result => {
            const agencies = result.records
                .flatMap(patrimony => patrimony.sectors
                    .flatMap(sector => sector.agencies));
            this.setState({ agencies: agencies.filter(a => a.active) })
        })
    }

    getManagers = () => {
        UserApi.getUsersList({ groupId: this.props.groupId, role: 'ORFI-MANAGER' }).then(result => {
            this.setState({ managers: result })
        })
    }

    syncUserWithLdap = () => {
        this.setState({ syncing: true }, () => {
            UserApi.syncUserListWithLdap({ groupId: this.props.groupId }).then(() => {
                this.fetchUsers();
                this.getManagers();
                successToast(I18n.t('SuccessSync'));
            }).catch(() => {
                this.setState({ syncing: false });
                errorToast(I18n.t('ErrorSync'));
            });
        })
    }

    getUserAgencyRelationsExport = () => {
        UserApi.getUserAgencyRelationsExport({ groupId: this.props.groupId }).then((response) => {
            successToast(I18n.t('SuccessExport'));
            if (response) {
                fileDownload(response, 'exportUserAgencyRelations.csv');
            }
        }
        ).catch(() => errorToast(I18n.t('ErrorExport')))
    }

    handleChange = (type, index, items) => {
        let users = [...this.state.users];
        users[index][type] = items;
        this.setState({
            users: users
        });
    }

    handlePagination = (pageToShow) => {
        this.setState({
            currentPage: pageToShow
        }, this.fetchUsers)
    }

    onSearchValueChange = (event) => {
        this.setState({
            searchValue: event.target.value
        }, this.fetchUsers);
    }


    toggleModalImportCsv = () => {
        this.setState({
            showModalCsv: !this.state.showModalCsv,
        });
    };

    handleFilterChange(filterKey, filterValue) {
        let filterList = this.state.filterList;
        if (filterKey === 'hasNoAgencies' && filterValue) {
            filterList['agency'] = ''
        }
        if (filterKey === 'agency' && filterValue.length > 0) {
            filterList['hasNoAgencies'] = false
        }
        filterList[filterKey] = filterValue;
        this.setState({
            filterList,
            currentPage: 0
        }, this.fetchUsers);
    };

    importCsv = (item, isDryRun) => {
        warningToast(I18n.t('PendingImport'));
        this.setState({ syncing: true })
        UserApi.importCsvUserRelations(item, { groupId: this.props.groupId }, isDryRun).then((response) => {
            !isDryRun && this.fetchUsers();
            if (response) {
                errorToast(I18n.t('ErrorImport'))
                fileDownload(response, 'rapport-erreurs-gardiens.txt');
                return;
            }

            if (!response) {
                successToast(I18n.t(isDryRun ? 'SuccessImportDryRun' : 'SuccessImport'));
            }
        }).catch((e) => {
            errorToast(I18n.t('ErrorImport'))
        })
            .finally(() => this.setState({ syncing: false }))
    };

    renderBody(users, agencies) {
        if (users && users.length > 0) {
            return (
                <tbody>
                    {users.map((user, index) => <EstateUsersEditionTableRow
                        key={user.id}
                        user={user}
                        userList={users}
                        agencies={agencies}
                        managers={this.state.managers}
                        handleChange={this.handleChange.bind(this)}
                        fetchUsers={this.fetchUsers.bind(this)}
                        index={index} />)}
                </tbody>
            )
        } else {
            return null;
        }
    }

    sortByNames() {
        this.setState((state) => ({
            sortBy: state.sortBy === 'lastname.DESC' ? 'lastname.ASC' : 'lastname.DESC',
        }), this.fetchUsers)
    }

    render() {
        const { error } = this.props;

        return (
            <div className="container-fluid">
                <div className="container">
                    <div className="gap-x-2 d-flex justify-content-end">
                        <div className="action-button small" onClick={this.toggleModalImportCsv}>
                            <FontAwesome name="upload" />
                        </div>
                        <div className="action-button small" onClick={this.getUserAgencyRelationsExport}>
                            <FontAwesome name="download" />
                        </div>
                    </div>
                    <div className="row user-edition">
                        <div className={"col-md-3 filters"}>
                            <EstateUserFilters
                                filterList={this.state.filterList}
                                handleChange={this.handleFilterChange.bind(this)}
                                lastnameValue={this.state.searchValue}
                                handleLastname={this.onSearchValueChange.bind(this)}
                                managers={this.state.managers}
                            />
                        </div>
                        <div className="col-md-9">
                            <p className="error textcenter"> {error} </p>
                            <Table responsive hover>
                                <thead>
                                    <tr key="0">
                                        <th>
                                            <div className="d-flex align-items-center justify-content-between" onClick={this.sortByNames.bind(this)}>
                                                <span>{I18n.t('UserEditionFullname')}</span>
                                                <span className={`${this.state.sortBy === 'lastname.DESC' ? 'icon-up' : 'icon-down'} mr-2`} />
                                            </div>
                                        </th>
                                        <th>{I18n.t('UserEditionAgencies')}</th>
                                        <th>{I18n.t('UserEditionSupervisors')}</th>
                                        <th>{I18n.t('UserEditionProfile')}</th>
                                        <th />
                                    </tr>
                                </thead>
                                {
                                    this.renderBody(this.state.users, this.state.agencies)
                                }
                            </Table>
                            {this.state.users.length > 0 && this.state.pageCount > 1 && <Pagination
                                onChange={this.handlePagination}
                                pages={this.state.pageCount}
                                currentPage={this.state.currentPage}
                                totalCount={this.state.totalCount}
                            />}
                        </div>
                    </div>
                </div>
                {this.state.syncing && <Loader />}
                <ModalImportCsv
                    showModal={this.state.showModalCsv}
                    toggleModal={this.toggleModalImportCsv}
                    importCsv={this.importCsv}
                    type="userRelations"
                />
            </div>
        )
    }
}

export default connect()(withRouter(EstateUsersEditionTable));
