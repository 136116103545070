import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import I18n from '../../resources/i18n/I18n';
import EventApi, { DEFAULT_SIZE } from '../../services/eventApi';
import GroupApi from '../../services/groupApi';
import NotificationApi from "../../services/notificationApi";
import { errorToast } from '../../utils/toast';
import ActionBar from '../consult/ActionBar';
import Event from '../event/Event';
import Filters from '../filters/Filters';
import Loader from "../loader/Loader";
import Logo from '../logo/Logo';
import Pagination from '../pagination/Pagination';
import Title from '../typo/Title';
import './consult.scss';

class Consult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentPage: 0,
            pageCount: undefined,
            totalCount: 0,
            pageSize: DEFAULT_SIZE,
            tooMuchEvents: false,
            eventList: [],
            eventToExport: [],
            showFilters: false,
            group: null,
            currentUser: this.props.user,
            consultedEvents: []
        };
    }

    componentWillMount() {
        if (this.props.location.state && this.props.location.state.page) {
            this.setState({ currentPage: this.props.location.state.page })
        }
        if (this.state.currentUser && this.state.currentUser.group) {
            GroupApi.getGroupByCode(this.state.currentUser.group.id).then(group => this.setState({ group: group }))
                .catch(() => {
                    errorToast(I18n.t('ErrorFetchGroup'))
                });
        }
    }

    componentDidMount() {
        //Récupération intiale des événements
        if (this.state.eventList.length === 0) {
            this.fetchEvents(this.state.currentPage);
        }

        // Récupération des événements déjà consultés
        NotificationApi.getConsultedEvents().then(consulted => {
            this.setState({ consultedEvents: consulted.rows });
        })
    };

    componentWillReceiveProps(nextProps) {
        //Actualisation des résultats au changement de filtres
        if (this.props.filters !== nextProps.filters) {
            this.fetchEvents(this.state.currentPage, nextProps.filters, this.state.pageSize);
        }
    };

    fetchEvents = (page, filters, pageSize = DEFAULT_SIZE) => {
        this.setState({ loading: true, eventToExport: []})
        //si changement de filtres, on remet sur la première page
        if (filters) {
            page = 0
        }
        EventApi.getEventList(
            filters ? filters : this.props.filters,
            page,
            this.state.pageSize ? this.state.pageSize : pageSize
        ).then((eventListResponse) => {
            this.setState(
                {
                    eventList: eventListResponse.records,
                    pageCount: eventListResponse.metadata.pageCount,
                    currentPage: page ? page : 0,
                    totalCount: eventListResponse.metadata.totalCount,
                });
        }).catch(() => {
            errorToast(I18n.t('ErrorFetchEvents'));
        }).finally(() => {
            this.setState((state) => ({ ...state, loading: false }));
        });
    };

    updateEventToExport = (e) => {
        this.setState({ tooMuchEvents: false });

        if (e.target.checked) {
            const event = this.state.eventList.find(event => event.id.toString() === e.target.value);
            this.setState({ eventToExport: [...this.state.eventToExport, event] });
        } else {
            this.setState({ eventToExport: this.state.eventToExport.filter(event => event.id.toString() !== e.target.value) });
        }
    };

    selectAllEventToExport = (e) => {
        this.setState({ tooMuchEvents: false });

        if (e.target.checked) {
            this.setState({ eventToExport: [...this.state.eventList] });
        } else {
            this.setState({ eventToExport: [] });
        }
    };

    toggleFilters = (show) => {
        this.setState({ showFilters: show });
    };

    markAsRead = () => {
        if (this.state.eventToExport.length > 0) {
            NotificationApi.markAsRead(this.state.eventToExport.map(e => e.id)).then((consulted) => {
                this.setState({
                    consultedEvents: consulted.rows,
                    eventToExport: []
                });
            });
        }
    }

    fetchEventsWithPagination = (event) => {
        const pageSize = parseInt(event.target.value, 10)
        this.setState({
            pageSize: pageSize
        });
        this.fetchEvents(0, this.state.filters, pageSize);
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.loading ? <Loader displayMessage /> : null}
                <Logo />
                <div className="container">
                    <div className="row">
                        <Title text={I18n.t('ConsultTitle')} />
                        <div className="container d-flex justify-content-end mb-4">
                            <ActionBar
                                actions={{ export: true }}
                                resultsCount={this.state.eventList.length}
                                addEvent
                                toggleFilters={this.toggleFilters}
                                filters={this.props.filters}
                                eventsToExport={this.state.eventToExport || []}
                                markAsRead={this.markAsRead} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-md-3 filters " + (!this.state.showFilters ? 'hidden' : '')}>
                            <Filters toggleFilters={this.toggleFilters} />
                        </div>
                        <div className="col-12 col-md-9 mt-3">
                            {this.state.pageCount !== 0 &&
                                <div className="d-flex mb-2">
                                    <div className="col-md-1 col-1 d-flex align-items-center justify-content-center">
                                        <input id='select-all'
                                            key='select-all'
                                            className="filter-checkbox"
                                            type="checkbox"
                                            checked={this.state.eventToExport.length === this.state.eventList.length}
                                            onChange={this.selectAllEventToExport} />
                                        <label htmlFor='select-all' onClick={(e) => e.stopPropagation()} />
                                    </div>
                                    <label for="select-all" className="input-subtext-title">{I18n.t('SelectAll')}</label>
                                </div>
                            }
                            {this.state.pageCount !== 0 ?
                                this.state.eventList.map((event) => {
                                    return (<Event
                                        key={event.id}
                                        event={event}
                                        page={this.state.currentPage}
                                        group={this.state.group}
                                        onExport={this.updateEventToExport}
                                        exportList={this.state.eventToExport}
                                        isUnread={(this.props.user.roles && ((this.props.user.roles.indexOf('ORFI-MANAGER') > -1) || (this.props.user.roles.indexOf('ORFI-DIRECTEUR') > -1)) &&
                                            this.state.consultedEvents.filter(e => e.idEvent === event.id).length === 0)} />)
                                })
                                :
                                <div className="no-result">
                                    <span className="icon-warning" />
                                    <div>{I18n.t('NoResult')}</div>
                                </div>
                            }
                            {this.state.eventList.length > 0 &&
                                <div className="d-flex justify-content-end">
                                    <Pagination
                                        onChange={this.fetchEvents}
                                        pages={this.state.pageCount}
                                        currentPage={this.state.currentPage}
                                        totalCount={this.state.totalCount}
                                    />
                                    <select className="browser-default custom-select ml-2 w-auto"
                                        onChange={this.fetchEventsWithPagination}
                                        value={this.state.pageSize}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.searchReducer,
        resources: state.resourceReducer,
        notifications: state.notificationReducer.notifications,
        user: state.userReducer.user
    };
};

export default connect(mapStateToProps)(withRouter(Consult));
