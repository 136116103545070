import React, { Component } from 'react';
import Title from '../typo/Title';
import I18n from 'resources/i18n/I18n';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import FontAwesome from 'react-fontawesome';
import ModalEdition from './ModalEdition';
import ModalDelete from './ModalDelete';
import ModalCreation from './ModalCreation';
import './configuration.scss';
import Logo from '../logo/Logo';

class LabelEditionTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalEdition: false,
            showModalDelete: false,
            showModalCreation: false,
            itemToCreate: null,
            itemToEdit: null,
            itemToDelete: null
        };
    }

    toggleModalCreation = () => {
        this.setState({ showModalCreation: !this.state.showModalCreation });
    };

    onCreateLabel = (e, item) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ itemToCreate: { idParent: item.id } });
        this.toggleModalCreation();
    };

    onLabelCreation = (label) => {
        const category = { ...this.state.itemToCreate, label: label };
        this.props.createLabel(category);
        this.setState({ itemToCreate: null });
    };

    toggleModalDelete = () => {
        this.setState({ showModalDelete: !this.state.showModalDelete });
    };

    onDeleteLabel = (item) => {
        //Ouverture de la popin de confirmation de suppression
        this.setState({ itemToDelete: item });
        this.toggleModalDelete();
    };

    deleteLabel = () => {
        this.props.deleteLabel(this.state.itemToDelete);
        this.setState({ itemToDelete: null });
    };

    onModifyItemLabel = (label) => {
        this.setState((state) => ({ ...state, itemToEdit: { ...state.itemToEdit, label: label } }));
    };

    toggleModalEdition = () => {
        this.setState({ showModalEdition: !this.state.showModalEdition });
    };

    onEditLabel = (item) => {
        //Ouverture de la popin avec l'élément à modifier
        this.setState({ itemToEdit: item });
        this.toggleModalEdition();
    };

    editLabel = () => {
        //Appel à l'édition du parent (call WS)
        this.props.editLabel(this.state.itemToEdit)
        this.setState({ itemToEdit: null });
    };

    renderItems = (item) => {
        return (item.subtypes.map((child) => {
            return <Accordion.Collapse key={child.id} eventKey={item.id}>
                <Card.Body>
                    <div className="configuration container d-flex justify-content-between align-items-center m-0">
                        <span className="category-subtitle">{child.label}</span>
                        <div className="d-flex">
                            <div className="action-button small" onClick={() => this.onDeleteLabel(child)}>
                                <FontAwesome name="trash" />
                            </div>
                            <div className="action-button small" onClick={(() => this.onEditLabel(child))}>
                                <FontAwesome name="pencil" />
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        }));
    };

    render() {
        const { items, error } = this.props;

        return (
            <div className="container-fluid">
                <Logo />
                <div className="container">
                    <div className="row">
                        <Title text={I18n.t('ConfigurationTitle')} />
                    </div>
                    <div className="row">
                        <p className="error text-center"> {error} </p>
                        <Accordion>
                            {items && items.map((item) => {
                                return <Card key={item.id}>
                                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                                        <div className="d-flex justify-content-between align-content-center">
                                            <span>{item.label}</span>
                                            <div className="action-button small" onClick={(e) => this.onCreateLabel(e, item)}>
                                                <FontAwesome name="plus" />
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    {this.renderItems(item)}
                                </Card>
                            })}
                        </Accordion>
                    </div>
                </div>
                <ModalCreation showModal={this.state.showModalCreation}
                    toggleModal={this.toggleModalCreation}
                    createLabel={this.onLabelCreation} />
                <ModalEdition showModal={this.state.showModalEdition}
                    toggleModal={this.toggleModalEdition}
                    item={this.state.itemToEdit}
                    editLabel={this.editLabel}
                    modifyItemLabel={this.onModifyItemLabel}
                />
                <ModalDelete showModal={this.state.showModalDelete}
                    toggleModal={this.toggleModalDelete}
                    deleteLabel={this.deleteLabel} />
            </div>
        )
    }
}

export default LabelEditionTree;