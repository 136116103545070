import React, {Component} from 'react';
import images from 'resources/assets/images';
import Title from '../../typo/Title';
import I18n from 'resources/i18n/I18n';
import ItemList from '../ItemList';
import GroupApi from 'services/groupApi'
import {withRouter} from 'react-router-dom';
import '../configuration.scss';
import {connect} from "react-redux";

class EstateEdition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            group: null,
        };
    }

    componentDidMount() {
        const groupId = new URLSearchParams(this.props.location.search).get('groupId')

        // Si le groupId est donné en paramètre de l'url on fetch l'organisme concerné
        if (groupId) {
            GroupApi.getGroupByCode(groupId).then(group => this.setState({ group : group }))
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <img className="logo-small" alt="ORFI" src={images.homeLogo}/>
                </div>
                <div className="container">
                    <div className="row">
                        <Title text={`${I18n.t('ConfigurationGroup')}${this.state.group && this.props.user.roles.indexOf('ORFI-ADMIN') > -1? ` : ${this.state.group.name || 'Nom inconnu'}` : ''}`}/>
                    </div>
                    <div className="row">
                        <ItemList title={I18n.t('EstateLabelConfiguration')} path={`/organismes/label${this.state.group ? `?groupId=${this.state.group.code}` : ''}`} />
                        <ItemList title={I18n.t('EstateConfiguration')} path={`/organismes/patrimoine${this.state.group ? `?groupId=${this.state.group.code}` : ''}`} />
                        <ItemList title={I18n.t('EstateAgencyConfiguration')} path={`/organismes/utilisateurs${this.state.group ? `?groupId=${this.state.group.code}` : ''}`} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user
    };
};

export default connect(mapStateToProps)(withRouter(EstateEdition));
