import React from 'react';
import I18n from '../../resources/i18n/I18n';
import 'moment/locale/fr';
import './filters.scss';
import './autocomplete.scss';
import FormControl from 'react-bootstrap/FormControl';
import Criteria from './Criteria';
import Form from "react-bootstrap/Form";
import Utils from "../../utils/utils";
import Autocompletion from './Autocompletion';

/**
 * Filtres pour la page configuration des équipes
*/
const EstateUserFilters = ({
    filterList,
    handleChange,
    handleLastname,
    lastnameValue,
    managers,
}) => {
    /**
     * Ajouter un responsable au filtre
     * @param {object} manager
     */
    const selectManager = (manager) => {
        const managers = filterList.supervisor || [];
        managers.push(manager.id);
        handleChange('supervisor', managers);
    }

    /**
     * Supprimer un responsable du filtre
     * @param {object} manager 
     */
    const removeManager = (manager) => {
        const managers = filterList.supervisor || [];
        handleChange('supervisor', managers.filter(managerId => managerId !== manager.id));
    }

    const onRoleChanged = ({target}) => {
        const role = target.name;
        const roles = filterList.role || [];

        handleChange('role', roles.indexOf(role) === -1 ? [...roles, role] : roles.filter(r => r !== role));
        
    }
    return (
        <div className="d-flex w-100 flex-column align-items-center justify-content-between">
            <div className="w-100 header-criteria text-uppercase">
                <span>{I18n.t('FiltersTitle')}</span>
            </div>
            <Criteria title={I18n.t('UserEditionFullname')}>
                <FormControl
                    name='lastname'
                    value={lastnameValue || ''}
                    onChange={handleLastname}
                />
            </Criteria>
            <Criteria title={I18n.t('EstateUserAgencyCriteria')}>
                <FormControl
                    name='agency'
                    value={filterList.agency || ''}
                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                />
                <div className='mt-3'>
                    <input id='hasNoAgencies'
                        key='hasNoAgencies'
                        name='hasNoAgencies'
                        className="filter-checkbox"
                        type="checkbox"
                        checked={filterList.hasNoAgencies}
                        onClick={(event) => handleChange(event.target.name, event.target.checked)}
                    />
                    <label className='p-0' htmlFor='hasNoAgencies' onClick={(e) => e.stopPropagation()}>
                        {I18n.t('allPatrimonies')}
                    </label>
                </div>
            </Criteria>

            <Criteria title={I18n.t('EstateUserSupervisorCriteria')}>

                <Autocompletion
                    suggestions={managers ? managers.filter(m => !filterList.supervisor || !filterList.supervisor.some((filterSupervisorId) => filterSupervisorId === m.id)).map(m => ({ id: m.id, label: `${m.firstname} ${m.lastname}` })) : []}
                    onSelect={selectManager}
                    initialValues={[]}
                    showOnFocus
                    onRemove={removeManager} />
            </Criteria>
            <Criteria title={I18n.t('UserEditionProfile')}>
                {
                    Utils.getAllRole().map((role, index) =>
                        <Form.Check
                            key={role}
                            id={role}
                            name={role}
                            value={role}
                            checked={(filterList.role && filterList.role.find(r => r === role))}
                            onClick={onRoleChanged}
                            label={Utils.getRoleName(role)}
                            type="checkbox"
                        />
                    )
                }
            </Criteria>
        </div>
    )
}

export default EstateUserFilters;
