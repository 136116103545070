import { Route, Redirect } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Composant qui permet de gérer les routes non authentifiées
 * 
 * @param {Object} props Les props
 * @param {Object} props.component Le composant à afficher
 * @param {string} props.path Le chemin de la route
 * @returns {React.Component} Le composant
 */
const UnAuthenticatedRoute = ({ component, path }) => {
    const user = useSelector(state => state.userReducer.user);
    const currentRoute = window.location.pathname;
    
    useEffect(() => {
        if(currentRoute === path && user && user.id) {
            window.location.href = '/accueil'
        }
    }, [user, currentRoute]);

    return currentRoute !== path || user && user.id ? <></>  : (
        <Route
            path={path}
            render={() => component}
        />
    )
}
export default UnAuthenticatedRoute;
