export default {
  CommonCancel: 'Annuler',
  CommonSave: 'Enregistrer',
  Next: 'Suivant',
  CommonYes: 'Oui',
  CommonNo: 'Non',

  LoginButton: 'Je m\'identifie',
  LoginMessageTitle: 'Bienvenue sur ORFI',
  LoginMessageBody: 'Votre authentification à la solution de recueil des faits d\'incivilités évolue.',
  LoginSupport: 'Support connexion utilisateurs',
  LoginEmailInputPlaceholder: 'Identifiant',
  LoginPasswordInputPlaceholder: 'Mot de passe',
  InvalidLoginCredentials: 'Les identifiants sont invalides',
  InvalidEmptyInputLogin: 'Veuillez renseigner tous les champs',
  LoginPasswordForgotten: 'Mot de passe oublié',

  HomeScreenTitleReport: 'Signaler',
  HomeScreenDescriptionReport: 'Rapportez toutes dégradations ou incivilités',
  HomeScreenTitleSearch: 'Consulter',
  HomeScreenDescriptionSearch: 'Accédez à l\'ensemble de vos signalements',
  HomeScreenTitleStats: 'Statistiques',
  HomeScreenDescriptionStats: 'Visualisez un ensemble de données',
  HomeScreenTitleHelp: 'Aide',
  HomeScreenDescriptionHelp: 'Trouvez des réponses à vos questions',
  HomeScreenTitleConfiguration: 'Configuration',
  HomeScreenDescriptionConfiguration: 'Administrez les données de l\'application',
  HomeScreenTitleOrganismes: 'Administration',
  HomeScreenDescriptionOrganismes: 'Administrez les données de votre organisme',

  ConsultTitle: 'Liste des événements',
  MapConsultTitle: 'Carte des événements',
  SelectAll: 'Sélectionner tout',
  SelectOneOption: 'Sélectionner une option',
  MarkAsRead: 'Marquer comme lu',

  ReportTitle: 'Signaler un événement',
  ReportEditTitle: 'Modifier le signalement d\'un événement',
  ReportGeneralInformationTitle: 'INFORMATIONS GÉNÉRALES',
  ReportGeneralInformationDateTitle: 'Date de l\'événement',
  ReportGeneralInformationHour: 'Tranche horaire',
  ReportGeneralInformationServiceTitle: 'Service',
  ReportGeneralInformationAgentTitle: 'Agent',
  ReportGeneralInformationServicePlaceholder: 'SERVICE ASSOCIÉ',
  ReportGeneralInformationAgentPlaceholder: 'AGENT ASSOCIÉ',
  ReportComplementaryInformationTitle: 'INFORMATION COMPLÉMENTAIRE',
  ReportTypeTitle: 'TYPE D\'ÉVÉNEMENT',
  ReportSubTypeTitle: 'SOUS-TYPE',
  ReportCategoryTitle: 'CATÉGORIE',
  ReportPlaceTitle: 'TYPE DE LIEU',
  ReportInputSite: 'Site',
  ReportInputBuilding: 'Bâtiment',
  ReportInputFloor: 'Étage',
  ReportSubPlaceTitle: 'SOUS-TYPE DE LIEU',
  ReportLocationTitle: 'LIEU DE L\'ÉVÉNEMENT',
  ReportInputComment: 'Commentaire sur l\'événement',
  ReportInputCommentDescription: 'Ces commentaires décrivent l\'événement constaté et les moyens envisagés pour réparer un dommage. Ils ne doivent jamais permettre l\'identification d\'une personne, en respect de la réglementation RGPD en vigueur.',
  ReportRecurrentFact: 'Fait récurrent ou permanent',
  ReportRecurrentFactDescription: 'Fait qui s\'étend sur plusieurs jours',
  ReportCamera: 'Espace avec vidéosurveillance',
  ReportInputLocationTypeholderSite: 'Saisir un site',
  ReportInputLocationTypeholderBuilding: 'Saisir un bâtiment',
  ReportInputLocationTypeholderFloor: 'Saisir un étage',
  SiteCriteria: 'Sites',
  EventSite: 'Site',
  ReportCameraDescription: 'L\'espace dispose de caméras de surveillance',
  ReportInputCommentPlaceholder: 'Saisir un commentaire',
  ReportSubmitError: 'Veuillez renseigner tous les champs obligatoires',
  ReportInputAddress: 'Saisir une adresse',
  ReportSaveAndDuplicate: 'Enregistrer & Dupliquer',
  FiltersTitle: 'Filtres',
  GroupCriteria: 'Groupes',
  GroupCodeCriteria: 'Code union',
  GroupNameCriteria: 'Nom',
  GroupCommentCriteria: 'Commentaire',
  CommentCriteria: 'Commentaire activé',
  TypeCriteria: 'Types',
  StatusCriteria: 'Statuts',
  CityCriteria: 'Commune',
  AuthorCriteria: 'Déclarant',
  PatrimonyCriteria: 'Patrimoine',
  RecurringCriteria: 'Récurrent',
  DateCriteria: 'Dates',
  StartDate: 'Du',
  EndDate: 'Au',

  StatusNew: 'Nouveau',
  StatusProcessing: 'En cours',
  StatusValidated: 'Validé',
  StatusRefused: 'Rejeté',

  EventVictim: 'Victime',

  EventType: 'Type',
  EventDate: 'Date',
  EventAuthor: 'Déclarant',
  EventAddress: 'Adresse',
  EventPlace: 'Lieu',
  EventCreation: 'Créé le ',
  EventBy: 'par ',
  EventCommentTitle: 'Commentaire sur le suivi',
  EventComment: 'Indiquer votre texte ici',

  NoResult: 'Aucun résultat ne correspond à la recherche.',

  LifecycleTitle: 'Cycle de vie',
  SaveButton: 'Enregistrer',
  Complaint: 'Plainte',
  Procedure: 'Procédure',
  ITT: 'ITT en nombre de jours',
  Prejudice: 'Préjudice estimé en €',

  DeleteConfirmTitle: 'Suppression',
  DeleteConfirmText: 'Etes-vous sûr de vouloir supprimer ce libellé ?',
  DeleteConfirmTextWithType: 'Etes-vous sûr de vouloir supprimer ce ',
  DeleteConfirmCancel: 'Annuler',
  DeleteConfirmOk: 'Confirmer',
  ExportConfirmSelected: 'Exporter la sélection',
  ExportConfirmAll: 'Exporter toutes les pages',
  ExportProgress: 'Export en cours, veuillez patienter...',
  ErrorDelete: 'La suppression est impossible, des signalements sont associés à cet élément. Vous pouvez cependant le désactiver s\'il s\'agit d\'une agence.',
  ErrorImport: 'Une erreur est survenue lors de l\'import',
  ErrorExport: 'Une erreur est survenue lors de l\'export',
  ErrorSync: 'Pas de données pour ce groupe dans le LDAP',
  SuccessImport: 'Import terminé',
  SuccessImportDryRun: 'Import à blanc terminé',
  PendingImport: 'Import des données en cours',
  SuccessExport: 'Export terminé',
  SuccessSync: 'Synchronisation avec le LDAP terminé',
  EditConfirmTitle: 'Edition d\'un libellé',
  EditConfirmTitleWithYpe: 'Edition de ',
  ModalConfirmCancel: 'Annuler',
  ModalConfirmOk: 'Confirmer',
  ModalConfirmDryRun: 'Importer à blanc',
  CreateConfirmTitle: 'Création d\'un libellé',
  CreateConfirmTitleWithType: 'Création de ',

  StatsTitle: 'Statistiques',
  StatsDashboardTitle: 'Mon tableau de bord',
  StatsDashboardSubTitle: 'Accédez aux graphiques prédéfinis permettant de piloter l\'activité',
  StatsMapTitle: 'Carte',
  StatsMapSubTitle: 'Visualisez l\'ensemble des signalements sur une carte',
  StatsDataTitle: 'Data',
  StatsDataSubTitle: 'Accédez à l\'outil Tableau et exploitez les données de votre organisme',

  ConfigurationTitle: 'Configuration des libellés',
  ConfigurationCategory: 'Catégories',
  ConfigurationPlace: 'Sous-lieux',
  ConfigurationProcess: 'Procédures',
  ConfigurationComplaint: 'Plaintes',
  ConfigurationGroup: 'Organismes',
  ConfigurationGroupTitle: 'Configuration des organismes',
  ConfigurationReconnection: 'Un rechargement de la page est nécessaire pour que les changements prennent effet',

  DashboardTitle: 'Tableau de bord',
  DashboardProcess: 'procédures',
  DashboardComplaint: 'plaintes',
  For: 'pour',
  Event: 'événements',
  DashboardPrejudice: 'de dégats estimés',
  DashboardEventDate: 'créés par jour',
  DashboardType: 'Type',
  DashboardSubtitle: 'd\'événements',
  DashboardVideo: 'Espaces surveillés',
  DashboardPie: 'Proportion',
  DashboardPieChart: 'Diagramme',
  DashboardBarChart: 'Histogramme',
  KeyNumbers: 'Chiffres clés',
  ByYear: 'par an',
  Cumbersome: 'dépôts d\'encombrants',
  Aggression: 'agressions',
  Degradation: 'dégradations de biens',

  StatusTitle: 'Statut',
  EventSubtitle: 'des événements',

  Yes: 'Oui',
  No: 'Non',

  ExportEvent: 'Export d\'événements',
  NoEventSelectedExport: 'Veuillez sélectionner au moins un événement',
  ExportTooMuchEvents: 'Vous pouvez extraire jusqu\'à 100 fiches. Activez les filtres.',
  ExportTitle: 'Fiche de signalement des incidents',
  ExportGroups: 'Bailleurs',
  ExportGroup: 'Bailleur',
  ExportAddress: 'Adresse',
  ExportAuthorRole: 'Fonction du déclarant',
  ExportContext: 'Contexte de l\'incident',
  ExportDate: 'Date de l\'incident',
  ExportHour: 'Heure',
  ExportLocation: 'Lieu de l\'incident',
  ExportCamera: 'Présence de caméras',
  ExportCommentOnEvent: 'Commentaire sur l\'événement',
  ExportCommentOnEventDescription: 'Ces données exportées sur des supports hors d\'ORFI  sont de la responsabilité des bailleurs',
  ExportTypes: 'Types d\'incidents',
  ExportDetails: 'Précisions de l\'incident',
  ExportVictim: 'Victime',
  ExportEventError: 'Une erreur est survenue lors de l\'export',

  GroupTableHeaderCode: 'Code union',
  GroupTableHeaderName: 'Nom',
  GroupTableHeaderComment: 'Commentaire',
  GroupTableHeaderOldField: 'Ancien affichage patrimoine',
  GroupTableHeaderOldFieldHelpStart: 'Vous avez deux possiblités en terme d\'affichage lors d\'un signalement pour votre organisation:',
  GroupTableHeaderOldFieldHelpOptionOne: '- Affichage des champs "Site", "Étage" et "Bâtiment" (interrupteur activé)',
  GroupTableHeaderOldFieldHelpOptionTwo: '- Affichage des champs "Patrimoine", "Secteur", "Agence" (interrupteur désactivé)',
  GroupTableHeaderOldFieldHelpOptionEnd: 'À noter que cette option n\'est disponible qu\'un certain temps pour assurer la transition vers l\'affichage des champs "Patrimoine", "Secteur", "Agence"',
  GroupTableUnknownName: 'Nom inconnu',
  GroupTableLogin: 'Se connecter en tant que',
  ErrorGroupSync: 'Une erreur est survenue lors de la synchronisation des organismes',
  ErrorGroupFetch: 'Une erreur est survenue lors de la récupération des organismes',
  ErrorGroupUpdate: 'Une erreur est survenue lors de la mise à jour de l\'organisme',

  EstateLabelConfiguration: 'Configuration des libellés',
  EstateConfiguration: 'Configuration du patrimoine',
  EstateConfigurationImportCsvTitle: 'Importez votre fichier .csv',
  EstateAgencyConfiguration: 'Configuration des équipes',
  EstateUserAgencyCriteria: 'Adresse',
  EstateUserSupervisorCriteria: 'Responsable',

  PaginationEvent: 'événement',
  PaginationEvents: 'événements',

  HelpTitle: 'Aide',
  HelpDoc: 'Manuel d\'utilisation',
  HelpRGPD: 'Clauses RGPD',
  HelpDirector: 'Pour toutes autres questions, contactez notre support ',
  HelpKeeper: 'Pour toutes autres questions sur l\'utilisation d\'ORFI, contactez votre référent ORFI.',

  DuplicateToastError: "Une erreur est survenue lors de l'enregistrement et la duplication du signalement.",
  DuplicateToastSuccess: "Le signalement {{id}} a bien été créé et dupliqué dans la nouvelle fiche.",
  SaveToastSuccess: "Le signalement {{id}} a bien été créé.",
  SaveToastError: "Une erreur est survenue lors de l'enregistrement du signalement.",
  EditToastSuccess: "Le signalement {{id}} a bien été modifié.",
  EditToastError: "Une erreur est survenue lors de la modification du signalement {{id}}.",

  SwitchDirectorSuccess: "Changement de rôle effectué",

  UserEditionFullname: "Prénom NOM",
  UserEditionAgencies: "Adresses",
  UserEditionSupervisors: "Responsables",
  UserEditionProfile: "Profil",
  allPatrimonies: "Tous les patrimoines",
  UserEditionSearchPlaceholder: "Rechercher des utilisateurs ...",
  PatrimonyEditionSearchPlaceholder: 'Rechercher des patrimoines ...',

  PatrimoniesImport: 'Import de patrimoines',
  AgencyImport: 'Import de lien utilisateur/adresse',
  SupervisorImport: 'Import de responsables',
  UserRelationsImport: 'Import des relations gardiens/agences/responsables',

  UserConfigurationNoagencies: 'Tous les patrimoines',
  UserConfigurationNosupervisors: 'Pas de responsable',

  Disabled: '(désactivée)',
  Label: 'Label',
  Code: 'Code',
  Required: 'Champ obligatoire',
  Retry: 'Réessayer',
  ErrorFetchLocationTypes: 'Une erreur est survenue lors de la récupération des types de lieux',
  ErrorFetchVictims: 'Une erreur est survenue lors de la récupération des victimes',
  ErrorFetchCategories: 'Une erreur est survenue lors de la récupération des catégories',
  ErrorFetchTypes: 'Une erreur est survenue lors de la récupération des types d\'événements',
  ErrorFetchSites: 'Une erreur est survenue lors de la récupération des sites',
  ErrorFetchCities: 'Une erreur est survenue lors de la récupération des communes',
  ErrorFetchPatrimonies: 'Une erreur est survenue lors de la récupération des patrimoines',
  ErrorFetchAuthors: 'Une erreur est survenue lors de la récupération des déclarants',
  ErrorFetchGroup: 'Une erreur est survenue lors de la récupération des informations de l\'organisme',
  ErrorFetchEvents: 'Une erreur est survenue lors de la récupération des événements',
  ErrorLogin: 'Une erreur de connexion est survenue, veuillez réessayer dans quelques instants ou nous contacter en cas de non résolution du problème'
}
