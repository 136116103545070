import React, { Component } from 'react';
import I18n from '../../../resources/i18n/I18n';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class ModalEdition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    editLabel = () => {
        if (!this.props.item.code) {
            this.setState({errorCode: I18n.t('Required')})
        }
        if (!this.props.item.label) {
            this.setState({errorLabel: I18n.t('Required')})
        }
        if (this.props.item.code && this.props.item.label) {
            this.setState({errorCode: false, errorLabel: false});
            this.props.editLabel().then(result => {
                if (result && result.description) {
                    this.setState({errorCode: result.description.message})
                } else {
                    this.toggleModal();
                    this.setState({label: '', code: '', errorCode: false, errorLabel: false});
                }
            });
        }
    };

    toggleModal = () => {
        this.setState({errorCode: false, errorLabel: false});
        this.props.toggleModal();
    };

    onChangeLabel = (e) => {
        this.props.modifyItemLabel(e.target.value)
    };

    onChangeCode = (e) => {
        this.props.modifyItemCode(e.target.value)
    };

    render() {
        return (
            <div>
                <Modal show={this.props.showModal} onHide={this.toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.props.title ?
                                    this.props.title :
                                    I18n.t('EditConfirmTitle')
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{I18n.t('Label')}</Form.Label>
                            <Form.Control
                                defaultValue={this.props.item ? this.props.item.label : ''}
                                validated={this.state.validatedLabel}
                                onChange={this.onChangeLabel} />
                            {this.state.errorLabel &&
                            <Form.Text className="error">
                                {this.state.errorLabel}
                            </Form.Text>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{I18n.t('Code')}</Form.Label>
                            <Form.Control
                                defaultValue={this.props.item ? this.props.item.code : ''}
                                validated={this.state.validatedCode}
                                onChange={this.onChangeCode} />
                            {this.state.errorCode &&
                            <Form.Text className="error">
                                {this.state.errorCode}
                            </Form.Text>
                            }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-back" onClick={this.toggleModal}>
                            {I18n.t('ModalConfirmCancel')}
                        </Button>
                        <Button className="primary" onClick={this.editLabel}>
                            {I18n.t('ModalConfirmOk')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}


export default ModalEdition;
