import React, { Component } from 'react';
import images from '../../../resources/assets/images';
import Title from '../../typo/Title';
import I18n from '../../../resources/i18n/I18n';
import { withRouter } from 'react-router-dom';
import '../configuration.scss';
import './UserTable.scss';
import FontAwesome from "react-fontawesome";
import CustomButton from "../../buttons/CustomButton";
import { connect } from "react-redux";
import GroupApi from '../../../services/groupApi'
import EstateUsersEditionTable from "./EstateUsersEditionTable";

class EstateUserEdition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: new URLSearchParams(this.props.location.search).get('groupId'),
            group: null,
        };
    }

    componentDidMount() {
        if (this.state.groupId) {
            GroupApi.getGroupByCode(this.state.groupId).then(group => {
                this.setState({
                    group: group
                });
            })
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <img className="logo-small" alt="ORFI" src={images.homeLogo} />
                </div>
                <Title text={`${I18n.t('EstateAgencyConfiguration')}${this.state.group && this.props.user.roles.indexOf('ORFI-ADMIN') > -1 ? ` : ${this.state.group.name || 'Nom inconnu'}` : ''}`} />
                <EstateUsersEditionTable groupId={this.state.groupId} />
                <div className="row justify-content-center p-4">
                    <CustomButton onClick={() => this.goBack()} icon={<FontAwesome name="arrow-left" />} small secondary />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user
    };
};

export default connect(mapStateToProps)(withRouter(EstateUserEdition));
