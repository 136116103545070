import React, { Component } from 'react';
import I18n from '../../resources/i18n/I18n';
import SimplePieChart from './SimplePieChart';
import CustomBarChart from './CustomBarChart';
import WrapperLoader from '../loader/WrapperLoader';

class SwitchChart extends Component {
    constructor(props) {
        super(props);
        //On sélectionne par défaut le diagramme par type
        this.state = {
            selectedChart: '0',
            selectedAggregation: 'idType'
        };
    }

    onSelectChart = (event) => {
        this.setState({ selectedChart: event.target.value });
    };

    getData = (aggregation) => {
        this.setState({ selectedAggregation: aggregation });
        this.props.getData(aggregation);
    };

    render() {
        const { data, canSwitch, title, subtitle, loading } = this.props;

        return (
            <WrapperLoader loading={loading}>
                <div className="dashboard-tile">
                    <div className="d-flex justify-content-between">
                        <div className="legend">
                            <div className="bold">{title}</div>
                            <div>{subtitle}</div>
                        </div>
                        {canSwitch &&
                            <div>
                                <select className="browser-default custom-select"
                                    onChange={this.onSelectChart}
                                    value={this.state.selectedChart}>
                                    <option value="0">
                                        {I18n.t('DashboardPieChart')}
                                    </option>
                                    <option value="1">
                                        {I18n.t('DashboardBarChart')}
                                    </option>
                                </select>
                            </div>
                        }
                    </div>
                    {this.state.selectedChart === '0' && <SimplePieChart data={data} />}
                    {this.state.selectedChart === '1' && <CustomBarChart data={data} />}
                    <div className="d-flex justify-content-around switch-chart">
                        <div className={this.state.selectedAggregation === 'idType' ? 'active' : ''} onClick={() => this.getData('idType')}>Type</div>
                        <div className={this.state.selectedAggregation === 'idSubtype' ? 'active' : ''} onClick={() => this.getData('idSubtype')}>Sous-type</div>
                        <div className={this.state.selectedAggregation === 'idCategory' ? 'active' : ''} onClick={() => this.getData('idCategory')}>Catégorie</div>
                    </div>
                </div>
            </WrapperLoader>
        )
    }
}

export default SwitchChart;