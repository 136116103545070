import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import './status.scss';

class StatusButton extends Component {
    render() {
        const { status } = this.props;
        const style = {
            color: status.color
        };

        return (
            <button className="action-button" onClick={() => this.props.addStatus(status)}>
                <FontAwesome style={style} name={status.icon} />
                <span>{status.label}</span>
            </button>
        );
    }
}

export default StatusButton;