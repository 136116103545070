import callApi from 'services';

const DEFAULT_SIZE = 10;

function filtersToUrlParams(filters) {
    let url = '';
    if (filters) {
        Object.entries(filters).forEach(([key, value]) => {
            if (value !== undefined) {
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        url += `${key}=${item}&`;
                    });
                } else {
                    url += `${key}=${value}&`;
                }
            }
        });
    }
    return url;
}

export default {
    getGroupList(filters, page, size) {
        const offset = page * DEFAULT_SIZE;
        return callApi(`groups?${filtersToUrlParams(filters)}&offset=${offset}&size=${size ? size : DEFAULT_SIZE}`, 'GET');
    },
    getGroupByCode(groupCode) {
        return callApi(`groups/${groupCode}`, 'GET');
    },
    syncGroupListWithLdap() {
        return callApi(`groups`, 'PUT');
    },
    updateGroup(group) {
        return callApi(`groups/${group.id}`, 'PUT', JSON.stringify(group));
    },
};