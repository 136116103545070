import { saveAs } from 'file-saver';
import moment from 'moment';
import callApi from 'services';
import eventService from 'services/eventService'
import { errorToast } from 'utils/toast';
export const DEFAULT_SIZE = 10;

function filtersToUrlParams(filters) {
    let url = '';
    if (filters) {
        Object.entries(filters).forEach(([key, value]) => {
            if (value !== undefined) {
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        url += `${key}=${item}&`;
                    });
                } else {
                    url += `${key}=${value}&`;
                }
            }
        });
    }
    return url;
}

export default {
    getEventList(filters, page, size = DEFAULT_SIZE, isMap = false) {
        let url = `events?`;
        if (page) {
            url += `&offset=${page * size}`;
        }
        if (size) {
            url += `&size=${size}`;
        }
        if(isMap) {
            url += `&isMap=true`;
        }

        url+= `&${filtersToUrlParams(filters)}`
        return callApi(url, 'GET');
    },
    getEventTypes() {
        return callApi('types', 'GET');
    },
    getLocationTypes() {
        return callApi('locationTypes', 'GET');
    },
    createEvent(event) {
        const eventApi = eventService.generateEventForCreation(event);
        return callApi('events', 'POST', JSON.stringify(eventApi));
    },
    updateEvent(event) {
        const eventApi = eventService.generateEventForCreation(event);
        return callApi(`events/${event.id}`, 'PUT', JSON.stringify(eventApi));
    },
    getEventAggregation(filters, criteria) {
        let groupBy = '';
        if (Array.isArray(criteria)) {
            criteria.forEach(c => groupBy += `groupBy=${c}&`);
        } else {
            groupBy = `groupBy=${criteria}`;
        }
        return callApi(`events/aggregation?${groupBy}&${filtersToUrlParams(filters)}`, 'GET');
    },
    getEventStatistics(filters) {
        return callApi(`events/statistics?${filtersToUrlParams(filters)}`, 'GET');
    },
    getEvent(id) {
        return callApi(`events/${id}`, 'GET');
    },
    deleteEvent(id) {
        return callApi(`events/${id}`, 'DELETE');
    },
    updateStatus(eventId, status) {
        return callApi(`events/${eventId}/status`, 'POST', JSON.stringify(status));
    },
    exportEvents(type, filters) {
        return callApi(`events/export?exportType=${type}&${filtersToUrlParams(filters)}`, 'GET')
        .catch((e) => {
            errorToast('Une erreur est survenue lors de l\'export');
        });
    }
};