import I18n from 'i18n-js';
import React from 'react';

/**
 * Composant qui permet d'afficher le lien vers le support
 * 
 * @returns {React.Component} Le composant
 */
const SupportContact = ({className}) => (
    <p className={`mt-1 text-white ${className}`}>
        <u>
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-white underline"
                href={'https://ush.atlassian.net/servicedesk/customer/portal/12/group/12/create/10378'}>
                {I18n.t('LoginSupport')}
            </a>
        </u>
    </p>
)
export default SupportContact;
