import React, { Component } from 'react';
import moment from 'moment';

class CustomTooltip extends Component {
    getLabel = (tooltip) => {
        if (tooltip.payload.date) {
            return moment(tooltip.payload.date).format('DD/MM/YYYY');
        }

        return tooltip.payload.name;
    };

    render() {
        const { active, payload } = this.props;
        
        if(!payload || payload.length === 0) {
            return null;
        }
        
        if (active) {
            const { payload } = this.props;
            return (
                <div className="custom-tooltip">
                    <span className="label">{`${this.getLabel(payload[0])} : ${payload[0].value}`}</span>
                </div>
            );
        }

        return null;
    }
}

export default CustomTooltip;