import React, { Component } from 'react';
import I18n from '../../../resources/i18n/I18n';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class ModalImportCsv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            file: null
        };
    }

    handleSubmissionWithDryRun = (isDryRun) => {
        this.props.toggleModal();
        this.props.importCsv(this.state.file, isDryRun);
        this.setState({file: null});
    };

    onChangeFile = (e) => {
        this.setState({file: e.target.files && e.target.files.length > 0 ?  e.target.files[0] : null});
    };

    render() {
        const capitalizedType = this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)
        return (
            <div>
                <Modal show={this.props.showModal} onHide={this.props.toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{I18n.t(capitalizedType+'Import')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Control type="file" accept='.csv' onChange={this.onChangeFile} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-back" onClick={() => {
                            this.props.toggleModal()
                            this.setState({file: null});
                        }}>
                            {I18n.t('ModalConfirmCancel')}
                        </Button>

                        <Button className="primary" onClick={() => this.handleSubmissionWithDryRun(true)} disabled={!this.state.file}>
                            {I18n.t('ModalConfirmDryRun')}
                        </Button>

                        <Button className="primary" onClick={() => this.handleSubmissionWithDryRun(false)} disabled={!this.state.file}>
                            {I18n.t('ModalConfirmOk')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}


export default ModalImportCsv;
