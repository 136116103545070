import React, { Component } from 'react';
import I18n from '../../resources/i18n/I18n';
import { connect } from 'react-redux';
import Filters from '../filters/Filters';
import Title from '../typo/Title';
import SwitchChart from './SwitchChart';
import SemiPieChart from './SemiPieChart';
import CustomPieChart from './CustomPieChart';
import EvolutionChart from './EvolutionChart';
import ActionBar from '../consult/ActionBar';
import ProgressTile from './ProgressTile';
import KeyNumber from './KeyNumber';
import TotalNumber from './TotalNumber';
import EventApi from '../../services/eventApi';
import CONSTANTS from '../../utils/constants';
import './dashboard.scss';
import Logo from '../logo/Logo';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            statusAggregation: [],
            typeAggregation: [],
            categoryAggregation: [],
            cameraAggregation: [],
            dateAggregation: [],
            totalCountEvents: 0,
            processesCount: 0,
            complaintsCount: 0,
            keyNumbers: {},
            prejudiceSum: 0,
            loading: true,
            loadingTopAggregations: true,
            loadingDateAggregation: true,
            loadingStatistics: true
        };
    }

    async loadStatistics(filters) {
        this.setState({ loading: true });
        Promise.all([
            await this.getData(['idType', 'currentStatus', 'camera'], filters),
            await this.getStatisticsNumber(filters),
            await this.getData(['date'], filters),
        ]).finally(() => {
            this.setState({ loading: false })
        });
    }

    componentDidMount() {
        this.loadStatistics();
    };

    componentWillReceiveProps(nextProps) {
        //Actualisation des résultats au changement de filtres
        this.loadStatistics(nextProps.filters);
    };

    getData = (aggregation, filters) => {
        if (!filters) {
            filters = this.props.filters;
        }
        if (aggregation.includes('date')) {
            this.setState({ loadingDateAggregation: true });
        } else {
            this.setState({ loadingTopAggregations: true });
        }

        return EventApi.getEventAggregation(filters, aggregation).then((response) => {
            response.forEach(r => {
                switch (r.name) {
                    case 'currentStatus':
                        this.handleStatusResult(r.result);
                        break;
                    case 'date':
                        this.handleDateResult(r.result);
                        break;
                    case 'camera':
                        this.handleCameraResult(r.result);
                        break;
                    case 'idType':
                    case 'idSubtype':
                    case 'idCategory':
                        this.handleTypeResult(r.result);
                        break;
                    default:
                        break;
                }
            });
        }).finally(() => {
            if (aggregation.includes('date')) {
                this.setState({ loadingDateAggregation: false });
            } else {
                this.setState({ loadingTopAggregations: false });
            }
        });
    };

    getStatisticsNumber = (filters) => {
        if (!filters) {
            filters = this.props.filters;
        }
        this.setState({ loadingStatistics: true });
        return EventApi.getEventStatistics(filters).then((response) => {
            this.setState({
                totalCountEvents: response.metadata.totalCount,
                processesCount: response.aggregations.find(a => a.aggregation === 'processes').count,
                complaintsCount: response.aggregations.find(a => a.aggregation === 'complaints').count,
                prejudiceSum: response.aggregations.find(a => a.aggregation === 'prejudice').count,
                keyNumbers: {
                    aggression: response.aggregations.find(a => a.aggregation === 'aggression').count,
                    degradation: response.aggregations.find(a => a.aggregation === 'degradation').count,
                    cumbersome: response.aggregations.find(a => a.aggregation === 'cumbersome').count
                }
            });
        }).finally(() => {
            this.setState({ loadingStatistics: false });
        });
    };

    handleStatusResult = (result) => {
        const statusList = result.map(status => {
            const statusConst = CONSTANTS.STATUS.find(x => x.id === status.currentStatus);
            return { ...status, name: statusConst.label, color: statusConst.color }
        });
        this.setState({ statusAggregation: statusList });
    };

    handleDateResult = (result) => {

        result.sort((a, b) => new Date(a.date) - new Date(b.date));
        this.setState({ dateAggregation: result });
    };

    handleCameraResult = (result) => {
        const cameraList = result.map(item => {
            let name = '';
            if (item.camera) {
                name = I18n.t('Yes');
            } else {
                name = I18n.t('No');
            }
            return { ...item, name: name }
        });
        this.setState({ cameraAggregation: cameraList });
    };

    handleTypeResult = (result) => {
        const typeList = result.map(type => {
            let name = '';
            if (type.category) {
                name = type.category.label;
            } else if (type.subtype) {
                name = type.subtype.label;
            } else if (type.type) {
                name = type.type.label;
            }
            return { ...type, name: name }
        });
        this.setState({ typeAggregation: typeList });
    };

    toggleFilters = (show) => {
        this.setState({ showFilters: show });
    };

    render() {
        return (
            <div className="dashboard">
                <Logo />
                <div className="container">
                    <div className="row">
                        <div>
                            <Title text={I18n.t('DashboardTitle')} />
                            <ActionBar toggleFilters={this.toggleFilters} />
                        </div>
                        <TotalNumber data={this.state.prejudiceSum}
                            unit={I18n.t('DashboardPrejudice')} />
                    </div>
                    <div className="row">
                        <div className={"col-md-2 filters " + (!this.state.showFilters ? 'hidden' : '')}>
                            <Filters toggleFilters={this.toggleFilters} loading={this.state.loading} />
                        </div>
                        <div className="col-12 col-md-10 charts">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <SwitchChart data={this.state.typeAggregation}
                                        canSwitch={true}
                                        getData={this.getData}
                                        title={I18n.t('DashboardType')}
                                        subtitle={I18n.t('DashboardSubtitle')}
                                        loading={this.state.loadingTopAggregations}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <SemiPieChart title={I18n.t('StatusTitle')}
                                        subtitle={I18n.t('EventSubtitle')}
                                        data={this.state.statusAggregation}
                                        loading={this.state.loadingTopAggregations}
                                    />
                                </div>
                                <div className="col-12 col-md-12 col-lg-4">
                                    <CustomPieChart data={this.state.cameraAggregation}
                                        canSwitch={false}
                                        title={I18n.t('DashboardVideo')}
                                        subtitle={I18n.t('DashboardPie')}
                                        loading={this.state.loadingTopAggregations}
                                    />
                                </div>
                            </div>
                            <EvolutionChart data={this.state.dateAggregation}
                                title={I18n.t('Event')}
                                subtitle={I18n.t('DashboardEventDate')}
                                loading={this.state.loadingDateAggregation}
                            />
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <ProgressTile total={this.state.totalCountEvents}
                                        data={this.state.processesCount}
                                        unit={I18n.t('DashboardProcess')}
                                        totalUnit={I18n.t('Event')}
                                        loading={this.state.loadingStatistics}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <ProgressTile total={this.state.totalCountEvents}
                                        data={this.state.complaintsCount}
                                        unit={I18n.t('DashboardComplaint')}
                                        totalUnit={I18n.t('Event')}
                                        loading={this.state.loadingStatistics}
                                    />
                                </div>
                                <div className="col-12 col-md-12 col-lg-4">
                                    <KeyNumber
                                        data={this.state.keyNumbers}
                                        loading={this.state.loadingStatistics}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.searchReducer
    };
};

export default connect(mapStateToProps)(Dashboard);
