import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import FontAwesome from "react-fontawesome";
import userApi from "../../../services/userApi";
import CustomList from "./CustomList";
import Utils from "../../../utils/utils";
import AutocompletionWithoutListing from "./AutocompletionWithoutListing";

class EstateUsersEditionTableRow extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.switchMode = this.switchMode.bind(this);
        this.state = {
            editMode: false,
            copyMode: false,
            copyTarget: null
        }
    }

    switchMode = () => {
        this.setState((state) => ({
            editMode: !state.editMode
        }));
        if (this.state.editMode) {
            userApi.updateUser(this.props.user).then(() => this.props.fetchUsers());
        }
    }

    copyUserTo = () => {
        if (this.state.copyTarget) {
            let updatedUser = this.state.copyTarget
            updatedUser.agencies = [...this.props.user.agencies, ...updatedUser.agencies].reduce((previousValue, currentValue) =>
                {
                    if (!previousValue.some((agency) => agency.id === currentValue.id)) {
                        return [...previousValue, currentValue]
                    }
                    return previousValue;
                }, [])

            // updatedUser.subordinates = this.props.user.subordinates
            // updatedUser.supervisors = this.props.user.supervisors
            userApi.updateUser(updatedUser).then(() => this.props.fetchUsers());
        }
        this.setState((state) => ({
            copyMode: !state.copyMode,
            copyTarget: null
        }));
    }

    handleChange = (type, items) => {
        this.props.handleChange(type, this.props.index, items);
    }


    render() {
        const {firstname, lastname, agencies, supervisors, roles, username} = this.props.user;
        const isKeeper = roles[0] ? (roles[0].label === 'ORFI-GARDIEN') : false;
        return (
            <tr>
                <td className="col-2">{lastname ? firstname + ' ' + lastname : username}</td>
                <td className="col-4">
                    <CustomList
                        type="agencies"
                        items={agencies}
                        handleChange={this.handleChange.bind(this)}
                        editMode={this.state.editMode}
                        selectables={this.props.agencies}
                        toShow="label"
                    />
                </td>
                <td className="col-4">
                    {isKeeper && <CustomList
                        type="supervisors"
                        items={supervisors}
                        handleChange={this.handleChange.bind(this)}
                        editMode={this.state.editMode}
                        selectables={this.props.managers}
                        toShow="fullname"
                    />}
                </td>
                <td className="col-2" style={{color: isKeeper ? "red" : "blue"}}>{roles[0] ? Utils.getRoleName(roles[0].label).toUpperCase() : ''}</td>
                <td className="d-flex flex-column">
                    <div className="action-button small mb-3" onClick={this.switchMode}>
                        <FontAwesome name={this.state.editMode ? "save" : "edit"}/>
                    </div>
                    <div className={'d-flex'}>
                        <div className="action-button small" onClick={this.copyUserTo}>
                            <FontAwesome name={this.state.copyMode ? "paste" : "copy"}/>
                        </div>
                        {this.state.copyMode && <div style={{ width: '175px' }}>
                            <AutocompletionWithoutListing
                                suggestions={this.props.userList}
                                toShow={"fullname"}
                                onSelect={(target) => this.setState({copyTarget: target})}
                                keepSelection={true}
                            />
                            </div>
                        }
                    </div>
                </td>
            </tr>
        )
    }
}

export default connect()(withRouter(EstateUsersEditionTableRow));
