import React, { Component } from 'react';
import './customButton.scss';
import Button from 'react-bootstrap/Button';

const CustomButton = ({
  primary,
  secondary,
  small,
  type,
  onClick,
  title,
  icon,
  disabled
}) => {
  const getClassOfCustomButton = () => {
    let className = "btn"

    if (primary) {
      className += " primary"
    }
    else if (secondary) {
      className += " secondary"
    }
    if (small) {
      className += " small"
    }
    return className
  }

  return (
    <Button
      className={getClassOfCustomButton()}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {title ? title : icon}
    </Button >
  )
}
export default CustomButton;