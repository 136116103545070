import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import I18n from 'resources/i18n/I18n';
import FontAwesome from 'react-fontawesome';
import '../configuration.scss';
import Title from 'components/typo/Title';
import { Table } from 'react-bootstrap';
import groupApi from 'services/groupApi';
import GroupFilters from '../../filters/GroupFilters';
import Loader from 'components/loader/Loader';
import Switch from "react-switch";
import variables from '../configuration.scss';
import { withRouter } from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import ControlledPopup from "../InfoModal";
import Logo from '../../logo/Logo';
import UserApi from '../../../services/userApi';

const LabelEditionTree = ({ error }) => {
    const [groupList, setGroupList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(undefined);
    const [filterList, setFilterList] = useState({});
    const [syncing, setSyncing] = useState(false);
    const [currentError, setCurrentError] = useState(error);

    const fetchGroupList = () => {
        groupApi.getGroupList(filterList, currentPage)
            .then((groupListResponse) => {
                setSyncing(false);
                setGroupList(groupListResponse.records);
                setPageCount(groupListResponse.metadata.pageCount);
            })
            .catch(() => {
                setCurrentError(I18n.t('ErrorGroupFetch'));
            })
    }

    const handleCheck = (group) => {
        groupApi.updateGroup(group).then(() => {
            fetchGroupList();
        }).catch(() => {
            setCurrentError(I18n.t('ErrorGroupUpdate'));
        });
    };

    const handleFilterChange = (filterKey, filterValue) => {
        setFilterList({
            ...filterList,
            [filterKey]: filterValue
        });
        setCurrentPage(0);
    }
    
    const prevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }

    const nextPage = () => {
        if (currentPage < pageCount - 1) {
            setCurrentPage(currentPage + 1);
        }
    }

    const firstPage = () => {
        setCurrentPage(0);
    }

    const lastPage = () => {
        setCurrentPage(pageCount - 1);
    }

    const fetchGroupListDebounced = useCallback(debounce(fetchGroupList, 500), [filterList, currentPage]);
    useEffect(() => {
        fetchGroupListDebounced();
        fetchGroupList();
    }, []);

    useEffect(() => {
        fetchGroupListDebounced();
        return () => {
            fetchGroupListDebounced.cancel();
        };
    }, [filterList, currentPage, fetchGroupListDebounced]);

    

    return (
        <div className="container-fluid">
            <Logo />
            <div className="container">
                <div className="row d-flex justify-content-between align-items-center">
                    <Title text={I18n.t('ConfigurationGroupTitle')} />
                </div>
                <div className="row">
                    <div className="col-md-3 filters">
                        <GroupFilters
                            filterList={filterList}
                            handleChange={handleFilterChange}
                        />
                    </div>
                    <div className="col-md-9">
                        <p className="error text-center"> {currentError} </p>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>{I18n.t('GroupTableHeaderCode')}</th>
                                    <th>{I18n.t('GroupTableHeaderName')}</th>
                                    <th>{I18n.t('GroupTableHeaderComment')}</th>
                                    <th>
                                        <div className="d-flex align-items-center">
                                            <span>{I18n.t('GroupTableHeaderOldField')}</span>
                                            <ControlledPopup />
                                        </div>
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    groupList.map((group, index) => (
                                        <tr key={index}>
                                            <td className="col-2">{group.code}</td>
                                            <td className="col-2">{group.name || <i>{I18n.t('GroupTableUnknownName')}</i>}</td>
                                            <td className="col-2">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Switch
                                                        id={'switch-' + index}
                                                        className="mr-3 d-flex"
                                                        onChange={() => handleCheck({ ...group, enableComment: !group.enableComment })}
                                                        checked={group.enableComment}
                                                        onColor={variables.primary}
                                                        offColor={variables.secondary}
                                                        uncheckedIcon={null}
                                                        checkedIcon={null}
                                                        height={22}
                                                        width={40}
                                                    />
                                                </div>
                                            </td>
                                            <td className="col-4">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Switch
                                                        id={'switch-' + index}
                                                        className="mr-3 d-flex"
                                                        onChange={() => handleCheck({ ...group, oldDisplay: !group.oldDisplay })}
                                                        checked={group.oldDisplay}
                                                        onColor={variables.primary}
                                                        offColor={variables.secondary}
                                                        uncheckedIcon={null}
                                                        checkedIcon={null}
                                                        height={22}
                                                        width={40}
                                                    />
                                                </div>
                                            </td>
                                            <td
                                                title={I18n.t('GroupTableLogin')}
                                                onClick={() => UserApi.switchToGestionnaire(group.code)}
                                                className="patrimony-link">
                                                <div className="d-flex justify-content-center">
                                                    <FontAwesome name="sign-in" />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        {groupList.length > 0 && pageCount > 2 &&
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="action-button small m-3" onClick={firstPage}>
                                        <FontAwesome name="angle-double-left" />
                                    </div>
                                    <div className="action-button small m-3" onClick={prevPage}>
                                        <FontAwesome name="angle-left" />
                                    </div>
                                </div>
                                <span className='group-table-page'>
                                    Page <b>{currentPage + 1}</b> sur <b>{pageCount}</b>
                                </span>
                                <div className="d-flex justify-content-end align-items-center">
                                    <div className="action-button small m-3" onClick={nextPage}>
                                        <FontAwesome name="angle-right" />
                                    </div>
                                    <div className="action-button small m-3" onClick={lastPage}>
                                        <FontAwesome name="angle-double-right" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {syncing && <Loader />}
        </div>
    )
}

export default withRouter(LabelEditionTree);
