import React, { useEffect, useState } from 'react';
import './progress.scss';

const Progress = ({
    value
}) => {

    return (
        <div className="progress w-100">
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={value} aria-valuemin="0" aria-valuemax="100" style={{
                width: `${value}%`
            }}></div>
        </div>
    );
};

export default Progress;