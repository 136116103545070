import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from 'resources/i18n/I18n';
import Title from '../typo/Title';
import Status from '../status/Status';
import EventApi from '../../services/eventApi';
import ComplaintApi from '../../services/complaintApi';
import ProcessApi from '../../services/processApi';
import StatusButton from '../status/StatusButton';
import FontAwesome from 'react-fontawesome';
import CONSTANTS from '../../utils/constants';
import { fetchProcessSuccess, fetchComplaintSuccess } from 'store/actions/resourceActions';
import { Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import CustomButton from 'components/buttons/CustomButton';
import Logo from '../logo/Logo';

class Lifecycle extends Component {

  constructor(props) {
    super(props);

    this.state = {
      event: null,
      newStatus: null,
      redirectPath: null
    };
  }

  componentDidMount() {
    //Récupération de l'événement
    EventApi.getEvent(this.props.match.params.id).then((response) => {
          //Tri des statuts par date de création
          response.statuses.sort((a,b) =>
            new Date(a.date) - new Date(b.date)
          );
          this.setState({event: response});
          this.setState({ isFormDisabled: response.currentStatus === CONSTANTS.STATUS_LIST.REFUSED || response.currentStatus === CONSTANTS.STATUS_LIST.VALIDATED })
    });
    if (this.props.resources.processes.length === 0) {
      ProcessApi.getProcessList().then((response) =>
          this.props.dispatch(fetchProcessSuccess(response))
      )
    }
    if (this.props.resources.complaints.length === 0) {
      ComplaintApi.getComplaintList().then((response) =>
          this.props.dispatch(fetchComplaintSuccess(response))
      )
    }
  }

  // Permet de savoir si l'utilisateur a créé un nouveau statut
  userAddNewStatut = false;

  goBack = () => {
    this.props.history.goBack();
  };
  
  addStatus = (status) => {
    this.userAddNewStatut = true;
    const newStatus = {status: status.id};
    this.setState({newStatus: newStatus});
  };

  editStatus = (status) => {
    this.setState({newStatus: status});
  };

  saveStatus = () => {
    const redirectPath = {
      pathname : '/consulter',
      state: {
        page: this.props.location.state && this.props.location.state.page ? this.props.location.state.page : 0
      }
    }
    if (!this.userAddNewStatut) {
      this.setState({redirectPath: redirectPath});
      return;
    }
    EventApi.updateStatus(this.state.event.id, this.state.newStatus).then((response) => {
      this.setState({redirectPath: redirectPath});
    });
  };

  renderButtons = () => {
    //Si l'evenement est déjà validé ou rejeté, aucune action possible
    const { event } = this.state;

    if (event.currentStatus === CONSTANTS.STATUS_LIST.VALIDATED
        || event.currentStatus === CONSTANTS.STATUS_LIST.REFUSED) {
      return null;
    }
    return (
        <div>
          {event.currentStatus !== CONSTANTS.STATUS_LIST.PROCESSING && (
              <StatusButton status={CONSTANTS.STATUS[1]} addStatus={this.addStatus}/>
          )
          }
          <StatusButton status={CONSTANTS.STATUS[2]} addStatus={this.addStatus}/>
          <StatusButton status={CONSTANTS.STATUS[3]} addStatus={this.addStatus}/>
        </div>
    );
  };


  render() {
    const { event, newStatus } = this.state;
    const { processes, complaints } = this.props.resources;

    if (this.state.redirectPath) {
      return (<Redirect to={this.state.redirectPath} push/>)
    }

    return (
        <div className="container-fluid">
          <Logo/>
          <div className="container">
            <div className="row">
              <Title text={I18n.t('LifecycleTitle')}/>
            </div>
            <div className="row">
              { event && event.statuses.map((status) => {
                return <Status key={status.id}
                               status={status} 
                               readOnly={true}/>
              })}
            </div>
            { event &&
            <div className="row justify-content-center mb-4">
              {this.renderButtons()}
            </div>
            }
            { newStatus &&
              <Status status={newStatus} 
                      readOnly={false}
                      processes={processes}
                      complaints={complaints}
                      editStatus={this.editStatus}/>
            }
            <div className="row justify-content-center p-4">
              <div className="p-1">
               <CustomButton onClick={() => this.goBack()} icon={<FontAwesome name="arrow-left" />} small secondary />
              </div>
              {
                !this.state.isFormDisabled && <div className="p-1">
                    <CustomButton onClick={() => this.saveStatus()} title={I18n.t('CommonSave')} small primary />
                </div>
              }
            </div >
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resources: state.resourceReducer
  };
};

export default connect(mapStateToProps)(withRouter(Lifecycle));