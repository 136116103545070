import React, { Component } from 'react';
import I18n from 'resources/i18n/I18n';
import './pagination.scss';

class Pagination extends Component {
    render() {
        const { totalCount, pages, currentPage, onChange } = this.props;

        return (
            <nav>
                <ul className='pagination'>
                    { totalCount && <span className="primary-text-color d-flex align-items-center mr-3">
                        {totalCount} {I18n.t(totalCount > 1 ? 'PaginationEvents' : 'PaginationEvent')}
                    </span> }
                    <li className='page-item ml-1'>
                        <button className='page-link ' onClick={() => onChange(0)} disabled={currentPage === 0}>
                            <span className="icon-angle-double-left"/>
                        </button>
                    </li>
                    <li className='page-item ml-1'>
                        <button className='page-link' onClick={() => onChange(currentPage - 1)} disabled={currentPage === 0}>
                            <span className="icon-angle-left"/>
                        </button>
                    </li>
                    <li className={`page-item ml-1 ${currentPage > 0 ? '' : 'd-none'}`}>
                        <button className='page-link' onClick={() => onChange(currentPage - 1)}>
                            {currentPage}
                        </button>
                    </li>
                    <li className='page-item ml-1 '>
                        <button className='page-link active'>
                            {currentPage + 1}
                        </button>
                    </li>
                    <li className={`page-item ml-1 ${currentPage < pages - 1 ? '' : 'd-none'}`}>
                        <button className='page-link' onClick={() => onChange(currentPage + 1)}>
                            {currentPage + 2}
                        </button>
                    </li>
                    <li className='page-item ml-1'>
                        <button className='page-link' onClick={() => onChange(currentPage + 1)} disabled={currentPage >= pages - 1}>
                            <span className="icon-angle-right"/>
                        </button>
                    </li>
                    <li className='page-item ml-1'>
                        <button className='page-link' onClick={() => onChange(pages - 1)} disabled={currentPage >= pages - 1}>
                            <span className="icon-angle-double-right"/>
                        </button>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default Pagination;
