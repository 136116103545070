import React, { useEffect, useState } from 'react';
import Card from "react-bootstrap/Card";
import FontAwesome from "react-fontawesome";
import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import images from 'resources/assets/images';
import I18n from 'resources/i18n/I18n';
import GroupApi from 'services/groupApi';
import CustomButton from "../../buttons/CustomButton";
import Title from '../../typo/Title';
import ModalEdition from "../ModalEdition";
import '../configuration.scss';

const EstateEdition = ({ history, location }) => {
    const currentUser = useSelector(state => state.userReducer.user);
    const [group, setGroup] = useState({
        labelPatrimony: '',
        labelSector: '',
        labelAgency: ''
    });
    const [itemToEdit, setItemToEdit] = useState(null);
    const [showModalEdition, setShowModalEdition] = useState(false);

    useEffect(() => {
        const groupId = new URLSearchParams(location.search).get('groupId')

        // Si le groupId est donné en paramètre de l'url on refetch les libellés associés
        if (groupId) {
            getGroupLabels(groupId);
        }
        if (currentUser.group.id && !groupId) {
            getGroupLabels(currentUser.group.id);
        }
    }, [])

    const getGroupLabels = (groupId) => {
        GroupApi.getGroupByCode(groupId).then(setGroup)
    }

    const toggleModalEdition = () => {
        setShowModalEdition(!showModalEdition);
    };

    const onEditLabel = (itemLabel, labelType) => {
        //Ouverture de la popin avec l'élément à modifier
        setItemToEdit({ label: itemLabel, type: labelType });
        toggleModalEdition()
    };

    const onModifyItemLabel = (label) => {
        setItemToEdit({ ...itemToEdit, label: label });
    };

    const editLabel = () => {
        //Appel à l'édition du group (call WS)
        let toChange = {};
        if (itemToEdit.type === 'labelPatrimony') {
            toChange = {
                labelPatrimony: itemToEdit.label
            }
        } else if (itemToEdit.type === 'labelSector') {
            toChange = {
                labelSector: itemToEdit.label
            }
        } else if (itemToEdit.type === 'labelAgency') {
            toChange = {
                labelAgency: itemToEdit.label
            }
        }
        const newGroup = { ...group, ...toChange };
        setItemToEdit(null);
        GroupApi.updateGroup(newGroup).then(() =>
            //Récupération des types mis à jour
            getGroupLabels(group.code || currentUser.group.id)
        );
    };

    const goBack = () => {
        history.goBack();
    };

    const renderItem = (label, labelType) => (
        <div className='row'>
            <Card.Body key={label}>
                <div className="configuration container d-flex justify-content-between align-items-center m-0">
                    <span className="category-subtitle">{label}</span>
                    <div className="d-flex">
                        <div className="action-button small" onClick={(() => onEditLabel(label, labelType))}>
                            <FontAwesome name="pencil" />
                        </div>
                    </div>
                </div>
            </Card.Body>
        </div>
    );

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <img className="logo-small" alt="ORFI" src={images.homeLogo} />
            </div>
            <div className="container">
                <div className="row">
                    <Title text={`${I18n.t('EstateLabelConfiguration')}${group && currentUser.roles.indexOf('ORFI-ADMIN') > -1 ? ` : ${group.name || 'Nom inconnu'}` : ''}`} />
                </div>
                <span className="mb-3 text">{I18n.t('ConfigurationReconnection')}</span>
                {renderItem(group.labelPatrimony, 'labelPatrimony')}
                {renderItem(group.labelSector, 'labelSector')}
                {renderItem(group.labelAgency, 'labelAgency')}
                <div className="row justify-content-center p-4">
                    <CustomButton onClick={goBack} icon={<FontAwesome name="arrow-left" />} small secondary />
                </div>
            </div>
            <ModalEdition showModal={showModalEdition}
                toggleModal={toggleModalEdition}
                item={itemToEdit}
                editLabel={editLabel}
                modifyItemLabel={onModifyItemLabel} />
        </div>
    )
}

export default withRouter(EstateEdition);
