import React, { Component } from 'react';
import { ResponsiveContainer, BarChart, Bar, Tooltip, Cell, Legend } from 'recharts';
import CONSTANTS from '../../utils/constants';
import CustomTooltip from './CustomTooltip';

class CustomBarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            legend: this.getLegend(this.props.data)
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({legend: this.getLegend(nextProps.data)});
    };

    getLegend = (data) => {
        return data.map((x, index) => {
            return {value: x.name, type: 'square', color: CONSTANTS.COLORS[index % CONSTANTS.COLORS.length]};
        });
    };

    render() {
        const { data } = this.props;
        const { legend } = this.state;

        return (
            <div className="pie-chart">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={data}>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend payload={legend} />
                        <Bar dataKey="count" barSize={20} radius={[5, 5, 0, 0]}>
                            {
                                data.map((entry, index) => <Cell key={index} fill={CONSTANTS.COLORS[index % CONSTANTS.COLORS.length]}/>)
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

export default CustomBarChart;