import React, { Component } from 'react';
import I18n from '../../resources/i18n/I18n';
import ProgressBar from 'react-bootstrap/ProgressBar';
import WrapperLoader from '../loader/WrapperLoader';

class ProgressTile extends Component {
    getPourcentage = () => {
        let pourcent = 0;
        if (this.props.total) {
            pourcent = (this.props.data / this.props.total) * 100;
        }
        return (pourcent).toFixed(2);
    };

    render() {
        const { data, total, unit, totalUnit, loading } = this.props;

        return (
            <WrapperLoader loading={loading}>
                <div className="dashboard-tile progress-tile">
                    <div className="legend">
                        <div className="bold">{`${data} ${unit}`}</div>
                        <div>{`${I18n.t('For')} ${total} ${totalUnit}`}</div>
                    </div>
                    <div>
                        <div className="key-number">{this.getPourcentage()}%</div>
                        <ProgressBar now={this.getPourcentage()} />
                    </div>
                    <div className="progress-start">0%</div>
                    <div className="progress-end">100%</div>
                </div>
            </WrapperLoader>
        )
    }
}

export default ProgressTile;