import React, { useState } from 'react';
import Title from '../typo/Title';
import I18n from 'resources/i18n/I18n';
import FontAwesome from 'react-fontawesome';
import ModalEdition from './ModalEdition';
import ModalDelete from './ModalDelete';
import ModalCreation from './ModalCreation';
import './configuration.scss';
import Logo from '../logo/Logo';

const LabelEdition = ({
    items,
    error,
    editLabel,
    createLabel,
    deleteLabel
}) => {
    const [showModalEdition, setShowModalEdition] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalCreation, setShowModalCreation] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);


    const toggleModalCreation = () => {
        setShowModalCreation(!showModalCreation);
    };

    const onLabelCreation = (label) => {
        createLabel(label);
    };

    const toggleModalDelete = () => {
        setShowModalDelete(!showModalDelete);
    };

    const onDeleteLabel = (item) => {
        //Ouverture de la popin de confirmation de suppression
        setItemToDelete(item);
        toggleModalDelete();
    };

    const deleteLabelConfirm = () => {
        deleteLabel(itemToDelete);
        setItemToDelete(null);
    };

    const toggleModalEdition = () => {
        setShowModalEdition(!showModalEdition);
    };

    const onEditLabel = (item) => {
        //Ouverture de la popin avec l'élément à modifier
        setItemToEdit(item);
        toggleModalEdition();
    };

    const onModifyItemLabel = (label) => {
        setItemToEdit((state) => ({ ...state, label }));
    };

    const editLabelConfirm = () => {
        if (itemToEdit && itemToEdit.label && itemToEdit.label !== '') {
            editLabel(itemToEdit)
            setItemToEdit(null);
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <Logo />
                <div className="container">
                    <div className="row justify-content-between">
                        <Title text={I18n.t('ConfigurationTitle')} />
                        <div className="action-button small" onClick={toggleModalCreation}>
                            <FontAwesome name="plus" />
                        </div>
                    </div>
                    <div className="row">
                        <p className="error text-center"> {error} </p>
                        {items && items.map((item) => {
                            return <div key={item.id} className="configuration container d-flex justify-content-between align-items-center">
                                <span className="category-subtitle">{item.label}</span>
                                <div className="d-flex">
                                    <div className="action-button small" onClick={() => onDeleteLabel(item)}>
                                        <FontAwesome name="trash" />
                                    </div>
                                    <div className="action-button small" onClick={() => onEditLabel(item)}>
                                        <FontAwesome name="pencil" />
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <ModalCreation showModal={showModalCreation}
                toggleModal={toggleModalCreation}
                createLabel={onLabelCreation} />
            <ModalEdition showModal={showModalEdition}
                toggleModal={toggleModalEdition}
                item={itemToEdit}
                editLabel={editLabelConfirm}
                modifyItemLabel={onModifyItemLabel}
            />
            <ModalDelete showModal={showModalDelete}
                toggleModal={toggleModalDelete}
                deleteLabel={deleteLabelConfirm} />
        </div>
    )
}

export default LabelEdition;