import callApi from 'services';

export default {
    getProcessList() {
        return callApi('processes', 'GET');
    },
    addProcess(process) {
        return callApi(`processes`, 'POST', JSON.stringify(process));
    },
    editProcess(process) {
        return callApi(`processes/${process.id}`, 'PUT', JSON.stringify(process));
    },
    deleteProcess(id) {
        return callApi(`processes/${id}`, 'DELETE');
    }
};