import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from '../../resources/i18n/I18n';
import ComplaintApi from '../../services/complaintApi';
import { fetchComplaintSuccess, deleteComplaintSuccess, editComplaintSuccess, addComplaintSuccess } from 'store/actions/resourceActions';
import LabelEdition from './LabelEdition';
import CustomButton from 'components/buttons/CustomButton';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router-dom';

class ComplaintEdition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        if (this.props.resources.complaints.length === 0) {
            ComplaintApi.getComplaintList().then((response) =>
                this.props.dispatch(fetchComplaintSuccess(response))
            )
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    deleteComplaint = (complaint) => {
        this.setState({ error: null });
        ComplaintApi.deleteComplaint(complaint.id).then(() =>
            this.props.dispatch(deleteComplaintSuccess(complaint))
        ).catch(() =>
            this.setState({ error: I18n.t('ErrorDelete') })
        )
    };

    editComplaint = (complaint) => {
        ComplaintApi.editComplaint(complaint).then(() =>
            this.props.dispatch(editComplaintSuccess(complaint))
        )
    };

    createComplaint = (label) => {
        ComplaintApi.addComplaint({ label: label }).then((complaint) =>
            this.props.dispatch(addComplaintSuccess(complaint))
        )
    };

    render() {
        const { complaints } = this.props.resources;

        return (
            <div className="container-fluid">
                <LabelEdition items={complaints}
                    deleteLabel={this.deleteComplaint}
                    editLabel={this.editComplaint}
                    createLabel={this.createComplaint}
                    error={this.state.error} />
                <div className="row justify-content-center p-4">
                    <CustomButton onClick={() => this.goBack()} icon={<FontAwesome name="arrow-left" />} small secondary />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        resources: state.resourceReducer
    };
};

export default connect(mapStateToProps)(withRouter(ComplaintEdition));