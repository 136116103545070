import React from 'react';
import Title from '../typo/Title';
import ItemList from '../configuration/ItemList';
import I18n from 'resources/i18n/I18n';
import Logo from '../logo/Logo';
import { useSelector } from 'react-redux';

const Stats = () => {
    const currentUser = useSelector(state => state.userReducer.user);

    return (
        <div className="container-fluid">
            <Logo />
            <div className="container">
                <div className="row">
                    <Title text={I18n.t('StatsTitle')} />
                </div>
                <div className="row">
                    <ItemList
                        title={I18n.t('StatsDashboardTitle')}
                        subtitle={I18n.t('StatsDashboardSubTitle')}
                        path="/statistiques/tableau-de-bord"
                        iconType="icon-bar-chart"
                    />
                    <ItemList
                        title={I18n.t('StatsMapTitle')}
                        subtitle={I18n.t('StatsMapSubTitle')}
                        path="/statistiques/carte"
                        iconType="icon-map"
                    />
                    {currentUser.roles &&
                        (currentUser.roles.indexOf('ORFI-DIRECTEUR') > -1 || currentUser.roles.indexOf('ORFI-ADMIN') > -1
                            || currentUser.roles.indexOf('ORFI-GESTIONNAIRE') > -1) &&
                        <ItemList
                            title={I18n.t('StatsDataTitle')}
                            subtitle={I18n.t('StatsDataSubTitle')}
                            path="https://donneeshlm.union-habitat.fr/#/views/ORFI/ORFI-data"
                            iconType="icon-pictoTab"
                            openNewTab
                        />
                    }
                </div>
            </div>
        </div>
    )
}
export default Stats;
