import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import './infoModal.scss.css'
import Image from "react-bootstrap/Image";
import images from "../../resources/assets/images";
import I18n from 'resources/i18n/I18n';

const ControlledPopup = () => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  return (
    <div>
      <Image
        className="icon"
        onClick={() => setOpen(o => !o)}
        src={images.iconHelp}>
      </Image>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="custom-modal">
          <span className="close" onClick={closeModal}>&times;</span>
          <div className="modalInfos">
            <p className="font-weight-bold">{I18n.t('GroupTableHeaderOldFieldHelpStart')}</p>
            <p className="font-italic">{I18n.t('GroupTableHeaderOldFieldHelpOptionOne')}</p>
            <p className="font-italic">{I18n.t('GroupTableHeaderOldFieldHelpOptionTwo')}</p>
            <p>{I18n.t('GroupTableHeaderOldFieldHelpOptionEnd')}</p>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default ControlledPopup