import callApi from 'services';

export default {
    getComplaintList() {
        return callApi('complaints', 'GET');
    },
    addComplaint(complaint) {
        return callApi(`complaints`, 'POST', JSON.stringify(complaint));
    },
    editComplaint(complaint) {
        return callApi(`complaints/${complaint.id}`, 'PUT', JSON.stringify(complaint));
    },
    deleteComplaint(id) {
        return callApi(`complaints/${id}`, 'DELETE');
    }
};