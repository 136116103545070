import React, { Component } from 'react';
import I18n from 'resources/i18n/I18n';
import Form from 'react-bootstrap/Form';
import Loader from 'components/loader/Loader';
import CustomButton from 'components/buttons/CustomButton';
import Datetime from 'react-datetime';
import eventService from 'services/eventService'
import EventApi from 'services/eventApi'
import VictimApi from 'services/victimApi'
import PatrimonyApi from 'services/patrimonyApi'
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { fetchTypesSuccess, fetchLocationsSuccess, fetchVictimSuccess, fetchPatrimoniesSuccess } from 'store/actions/resourceActions';
import { withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import Switch from 'react-switch'
import EventCreationMap from 'components/eventCreationMap/EventCreationMap'
import IconCard from 'components/iconCard/IconCard'
import 'moment/locale/fr';
import variables from './report.scss';
import { errorToast, successToast } from "../../utils/toast";
import Logo from '../logo/Logo';
import CONSTANTS from "../../utils/constants";

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEvent: {
        user: this.props.currentUser,
        group: this.props.currentUser.group,
        date: moment(),
        type: null,
        subtype: null,
        category: null,
        locationType: null,
        locationSubtype: null,
        recurrent: false,
        camera: false,
        address: null,
        comment: null,
        building: null,
        floor: null,
        victims: [],
        id: this.props.match.params.id,
        idPatrimony: null,
        idSector: null,
        idAgency: null
      },
      currentUser: this.props.currentUser,
      showComment: this.props.currentUser.group.enableComment,
      showError: false,
      loading: false,
      redirectPath: null,
      editMode: false,
      page: 0
    };
  }

  componentWillMount() {
    if (this.props.match && this.props.match.params.id) {
      let page = 0;
      if (this.props.location && this.props.location.state && this.props.location.state.page) {
        page = this.props.location.state.page;
      }
      this.setState({ editMode: true, loading: true, page: page });
    }
  }

  componentDidMount() {
    window.onkeydown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    }
    //En cas d'erreur, on laisse le champ commentaire désactivé et on récupère l'évenènement
    this.fetchEvents(this.state.currentPage).then(() => {
      this.fetchEvent();
    });
  }

  fetchEvent = () => {
    if (this.props.match && this.props.match.params.id) {
      EventApi.getEvent(this.props.match.params.id).then((response) => {
        eventService.normalizeEvent(response, this.props.types, this.props.locations);
        this.setState({ currentEvent: response, loading: false });
        this.setState({ isFormDisabled: this.state.currentEvent.currentStatus === CONSTANTS.STATUS_LIST.REFUSED || this.state.currentEvent.currentStatus === CONSTANTS.STATUS_LIST.VALIDATED })
      }).catch(() => {
        this.setState({ loading: false });
        this.redirectTo('/consulter');
      });
    }
  };

  fetchEvents = () => {
    return EventApi.getEventTypes().then((types) => {
      this.props.dispatch(fetchTypesSuccess(types));
      return EventApi.getLocationTypes().then((locations) => {
        this.props.dispatch(fetchLocationsSuccess(locations));
        return VictimApi.getVictimList().then((victims) => {
          this.props.dispatch(fetchVictimSuccess(victims));
          return PatrimonyApi.getPatrimonyList({ active: 1 }).then(patrimonies => {
            this.props.dispatch(fetchPatrimoniesSuccess(patrimonies));
            // Lors d'une création, si l'utilisateur ne peut voir qu'une agence du patrimoine, on préremplit les champs
            if (!this.state.editMode) {
              let idPatrimony = null;
              let idSector = null;
              let idAgency = null;
              if (patrimonies.records.length === 1) {
                idPatrimony = patrimonies.records[0].id;
                if (patrimonies.records[0].sectors.length === 1) {
                  idSector = patrimonies.records[0].sectors[0].id;
                  if (patrimonies.records[0].sectors[0].agencies.length === 1) {
                    idAgency = patrimonies.records[0].sectors[0].agencies[0].id;
                  }
                }
              }
              this.setState({
                currentEvent: {
                  ...this.state.currentEvent,
                  idPatrimony: idPatrimony,
                  idSector: idSector,
                  idAgency: idAgency
                }
              });
            }
          });
        })
      });
    });
  };

  selectDate = (date) => {
    //La librairie datepicker ne gère pas la limite sur les heures,
    //on reset la date à la date/heure du jour si l'heure dépasse l'heure actuelle
    if (moment(date).isAfter(moment())) {
      date = moment();
    }
    this.setState({ currentEvent: { ...this.state.currentEvent, date: moment(date) } });
  };

  handleEventType = (id) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, type: this.props.types.find((x) => x.id === id), subtype: null, category: null } });
  };

  handleEventSubType = (id) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, subtype: this.state.currentEvent.type.subtypes.find((x) => x.id === id), category: null } });
  };

  handleEventCategory = (id) => {
    const currentCategory = this.state.currentEvent.subtype.categories.find((x) => x.id === id);
    this.setState({ currentEvent: { ...this.state.currentEvent, category: currentCategory, recurrent: !currentCategory.isRecurrentFact ? null : false } });

  };

  handleSiteInputChanged = (textSite) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, site: textSite.target.value } });
  }

  handleBuildingInputChanged = (textBuilding) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, building: textBuilding.target.value } });
  }

  handleFloorInputChanged = (textFloor) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, floor: textFloor.target.value } });
  }

  handleEventPlace = (id) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, locationType: this.props.locations.find((x) => x.id === id), locationSubtype: null } });
  };

  handleEventSubPlace = (id) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, locationSubtype: this.state.currentEvent.locationType.subtypes.find((x) => x.id === id) } });
  };

  handleEventAddress = (address) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, address: address } });
  };

  handlePatrimonyInputChanged = (idPatrimony) => {
    this.setState({
      currentEvent: {
        ...this.state.currentEvent,
        idPatrimony: parseInt(idPatrimony.target.value),
        idSector: null,
        idAgency: null
      }
    });
  };

  handleSectorInputChanged = (idSector) => {
    this.setState({
      currentEvent: {
        ...this.state.currentEvent,
        idSector: parseInt(idSector.target.value),
        idAgency: null
      }
    });
  };

  handleAgencyInputChanged = (idAgency) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, idAgency: parseInt(idAgency.target.value) } });
  };

  handleCommentInputChanged = (textComment) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, comment: textComment.target.value } });
  };

  switchRecurrentFact = (state) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, recurrent: state } });
  };

  switchCamera = (state) => {
    this.setState({ currentEvent: { ...this.state.currentEvent, camera: state } });
  };

  handleVictimChanged = (idVictim) => {
    const oldVictims = this.state.currentEvent.victims;
    if (oldVictims.includes(idVictim)) {
      //On enlève la victime
      this.setState({
        currentEvent: { ...this.state.currentEvent, victims: oldVictims.filter(elem => elem !== idVictim) }
      });
    } else {
      //On ajoute la victime
      this.setState({
        currentEvent: { ...this.state.currentEvent, victims: [...oldVictims, idVictim] }
      });
    }
  };

  renderGeneralInfos = () => {
    return (
      <div>
        <h5 className="text-title mb-3 mt-5">{I18n.t('ReportGeneralInformationTitle')}
          <sup className="asterisk"> *</sup>
        </h5>
        <p className="input-text-title">{I18n.t('ReportGeneralInformationDateTitle')}
          <sup className="asterisk"> *</sup>
        </p>
        <Datetime
          locale="fr-ca"
          defaultValue={this.state.currentEvent.date || moment()}
          value={this.state.currentEvent.date}
          isValidDate={(d) => d.isBefore(new Date())}
          onChange={this.selectDate}
          className="date-input-container"
          timeFormat
          inputProps={{ disabled: this.state.isFormDisabled }} />
      </div>
    )
  };

  renderEventType = () => {
    return (
      <div>
        <h5 className="text-title mb-3 mt-5">{I18n.t('ReportTypeTitle')}
          <sup className="asterisk"> *</sup>
        </h5>
        <div className="row">
          {this.renderEventInfos(this.props.types, this.handleEventType, this.state.currentEvent.type)}
        </div>
      </div>
    )
  };

  renderEventSubType = () => {
    if (this.state.currentEvent.type) {
      return (
        <div>
          <h5 className="text-title mb-3 mt-5">{I18n.t('ReportSubTypeTitle')}
            <sup className="asterisk"> *</sup>
          </h5>
          <div className="row">
            {this.renderEventInfos(this.state.currentEvent.type.subtypes, this.handleEventSubType, this.state.currentEvent.subtype)}
          </div>
        </div>
      )
    }
    return null;
  };

  renderEventCategory = () => {
    if (this.state.currentEvent.subtype) {
      return (
        <div className="mb-6">
          <h5 className="text-title mb-3 mt-5">{I18n.t('ReportCategoryTitle')}
            <sup className="asterisk"> *</sup>
          </h5>
          {this.renderEventInfos(this.state.currentEvent.subtype.categories, this.handleEventCategory, this.state.currentEvent.category, this.state.currentEvent.subtype.iconName, true)}
        </div>
      )
    }
    return null;
  };

  renderEventPlace = () => {
    if (this.state.currentEvent.category || this.state.currentEvent.locationType) {
      return (
        <div>
          <h5 className="text-title mb-3 mt-5">{I18n.t('ReportPlaceTitle')}
            <sup className="asterisk"> *</sup>
          </h5>
          <div className="row">
            {this.renderEventInfos(this.props.locations, this.handleEventPlace, this.state.currentEvent.locationType)}
          </div>
        </div>
      )
    }
    return null;
  };

  renderEventSubPlace = () => {
    if (this.state.currentEvent.locationType) {
      return (
        <div className="mb-6">
          <h5 className="text-title mb-3 mt-5">{I18n.t('ReportSubPlaceTitle')}
            <sup className="asterisk"> *</sup>
          </h5>
          <div className="row">
            {this.renderEventInfos(this.state.currentEvent.locationType.subtypes, this.handleEventSubPlace, this.state.currentEvent.locationSubtype, this.state.currentEvent.locationType.iconName, true)}
          </div>
        </div>
      )
    }
    return null;
  };

  renderEventAddress = () => {
    if (this.state.currentEvent.locationSubtype) {
      return (
        <div className="map-container">
          <h5 className="text-title mb-3 mt-5">{I18n.t('ReportLocationTitle')}
            <sup className="asterisk"> *</sup>
          </h5>
          <EventCreationMap
            edit={this.state.editMode}
            value={this.state.currentEvent.address}
            onChange={this.handleEventAddress}
            disabled={this.state.isFormDisabled} />
        </div>
      )
    }
    return null;
  };

  renderSwitchButton = (state, modifyValue) => {
    return (
      <Switch
        className="mr-3 d-flex"
        onChange={(value) => modifyValue(value)}
        checked={state}
        onColor={variables.primary}
        offColor={variables.secondary}
        uncheckedIcon={null}
        checkedIcon={null}
        height={22}
        width={40}
        disabled={this.state.isFormDisabled}
      />
    )
  };

  renderVictimCheckbox = () => {
    return (
      <div>
        <p className="input-text-title mb-0">{I18n.t('EventVictim')}</p>
        <div className="row no-gutters">
          {
            this.props.victims.map((victim) => (
              <Form.Check
                key={victim.id}
                className="mr-0 col-12"
                checked={this.state.currentEvent.victims.includes(victim.id)}
                onChange={() => this.handleVictimChanged(victim.id)}
                inline label={victim.label}
                type="checkbox"
                id={victim.id}
                disabled={this.state.isFormDisabled}
              />
            ))
          }
        </div>
      </div>
    )
  };

  renderPatrimoniesInfos = () => (
    <div className="row mb-3">
      <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
        {
          this.renderEventInputInfos(
            this.state.currentEvent.idPatrimony,
            this.handlePatrimonyInputChanged,
            this.props.currentUser.group ? this.props.currentUser.group.labelPatrimony : '',
            I18n.t('SelectOneOption'),
            this.props.patrimonies.sort((a, b) => (a.label > b.label) ? 1 : -1))
        }
      </div>
      <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
        {
          this.renderEventInputInfos(
            this.state.currentEvent.idSector,
            this.handleSectorInputChanged,
            this.props.currentUser.group ? this.props.currentUser.group.labelSector : '',
            I18n.t('SelectOneOption'),
            this.props.patrimonies
              .flatMap(patrimony => patrimony.sectors
                .filter(sector => sector.idPatrimony && this.state.currentEvent.idPatrimony && sector.idPatrimony.toString() === this.state.currentEvent.idPatrimony.toString()))
              .sort((a, b) => (a.label > b.label) ? 1 : -1))
        }
      </div>
      <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
        {
          this.renderEventInputInfos(
            this.state.currentEvent.idAgency,
            this.handleAgencyInputChanged,
            this.props.currentUser.group ? this.props.currentUser.group.labelAgency : '',
            I18n.t('SelectOneOption'),
            this.props.patrimonies
              .flatMap(patrimony => patrimony.sectors
                .filter(sector => sector.idPatrimony && this.state.currentEvent.idPatrimony && sector.idPatrimony.toString() === this.state.currentEvent.idPatrimony.toString())
                .flatMap(sector => sector.agencies
                  .filter(agency => agency.idSector && this.state.currentEvent.idSector && agency.idSector.toString() === this.state.currentEvent.idSector.toString()))
              ).sort((a, b) => (a.label > b.label) ? 1 : -1))
        }
      </div>
    </div>
  )

  renderEventComplementaryInfos = () => {
    return (
      <div>
        <h5 className={`text-title my-3 ${this.state.currentEvent.locationSubtype ? "pt-5" : ""}`}>
          {I18n.t('ReportComplementaryInformationTitle')}
        </h5>
        {
          this.state.currentEvent.category && this.state.currentEvent.category.isRecurrentFact &&
          <>
            <div className="d-flex">
              <div key={`inline-switch-recurrent`}>
                {this.renderSwitchButton(this.state.currentEvent.recurrent, this.switchRecurrentFact)}
              </div>
              <span className="input-text-title">{I18n.t('ReportRecurrentFact')}</span>
            </div>
            <p className="input-subtext-title">{I18n.t('ReportRecurrentFactDescription')}</p>
          </>
        }
        <div className="d-flex">
          <div key={`inline-switch-camera`}>
            {this.renderSwitchButton(this.state.currentEvent.camera, this.switchCamera)}
          </div>
          <span className="input-text-title">{I18n.t('ReportCamera')}</span>
        </div>
        <p className="input-subtext-title">{I18n.t('ReportCameraDescription')}</p>
        {this.renderVictimCheckbox()}
        {this.props.currentUser.group && this.props.currentUser.group.oldDisplay &&
          <div className="row mb-3">
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
              {this.renderOldEventInputInfos(this.state.currentEvent.site, this.handleSiteInputChanged, I18n.t('ReportInputSite'), I18n.t('ReportInputLocationTypeholderSite'))}
            </div>
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
              {this.renderOldEventInputInfos(this.state.currentEvent.building, this.handleBuildingInputChanged, I18n.t('ReportInputBuilding'), I18n.t('ReportInputLocationTypeholderBuilding'))}
            </div>
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
              {this.renderOldEventInputInfos(this.state.currentEvent.floor, this.handleFloorInputChanged, I18n.t('ReportInputFloor'), I18n.t('ReportInputLocationTypeholderFloor'))}
            </div>
          </div>
        }
        {this.props.currentUser.group && !this.props.currentUser.group.oldDisplay && this.renderPatrimoniesInfos()}
      </div>
    )
  };

  renderOldEventInputInfos = (value, callback, title, subtitle) => (
    <div>
      <p className="input-text-title mt-3 mb-0">{title}</p>
      <Form.Control
        value={value || ''}
        placeholder={subtitle}
        onChange={callback}
        disabled={this.state.isFormDisabled} />
    </div>
  )

  renderEventInputInfos = (value, callback, title, subtitle, choices) => (
    <div>
      <p className="input-text-title mt-3 mb-0">{title}</p>
      <select
        className="custom-select form-control"
        onChange={callback}
        value={value || ''}
        disabled={this.state.isFormDisabled}
      >
        <option value="" selected>{subtitle}</option>
        {choices.map(choice => (
          <option value={choice.id}>{choice.label}</option>
        ))}
      </select>
    </div>
  );

  renderIconCard = (iconType, description, id, onClick, idSelected, customIcon, inline) => {
    return (
      <IconCard
        onClick={() => onClick(id)}
        key={id}
        iconType={customIcon ? 'icon-' + customIcon : 'icon-' + iconType}
        description={description}
        idSelected={idSelected === id}
        inline={inline}
        edit={!inline}
        disabled={this.state.isFormDisabled}
      />
    )
  };

  renderEventInfos = (list, onClick, objectSelected, customIcon, inline) => {
    if (list) {
      return (
        list.map((item) =>
          this.renderIconCard(item.iconName, item.label, item.id, onClick, objectSelected ? objectSelected.id : null, customIcon, inline)
        ))
    }
  };

  renderEventComment = () => {
    if (this.state.showComment) {
      return (
        <div>
          <span className="input-text-title mb-3">{I18n.t('ReportInputComment')}</span>
          <p className="input-subtext-title mb-3">{I18n.t('ReportInputCommentDescription')}</p>
          <Form.Control
            as="textarea" rows={3} maxLength="2047"
            value={this.state.currentEvent.comment || ''}
            placeholder={I18n.t('ReportInputCommentPlaceholder')}
            onChange={this.handleCommentInputChanged}
            disabled={this.state.isFormDisabled} />
        </div>
      )
    }
    return null
  }

  goBack = () => {
    this.props.history.replace('/consulter', ({ page: this.state.page }));
    this.props.history.goBack()
  }


  renderFormButtons = () => {
    return (
      <div className="submit-form-footer">
        {this.renderError()}
        <div className="row justify-content-center p-4">
          <div className="p-1">
            <CustomButton onClick={this.goBack} icon={<FontAwesome name="arrow-left" />} small secondary />
          </div>
          {!this.state.isFormDisabled ? <div className="p-1">
            <CustomButton title={I18n.t('CommonSave')} type='submit' small primary />
          </div> : <div className="p-1">
            <CustomButton title={I18n.t('Next')} type='button' onClick={() => { this.redirectTo({ pathname: `/evenement/${this.state.currentEvent.id}/status`, state: { page: this.state.page } }); }} small primary />
          </div>}

          {!this.state.editMode &&
            <div className="p-1">
              <CustomButton onClick={(event) => { this.onSubmit(event, true) }} title={I18n.t('ReportSaveAndDuplicate')} small primary />
            </div>
          }
        </div >
      </div>
    )
  };

  renderError = () => {
    return (
      <p className="login-error text-center mt-3">{this.state.showError ? I18n.t('ReportSubmitError') : ''}</p>
    )
  };

  onSubmit = (event, duplicate = false) => {
    event.preventDefault();
    //Gestion des erreurs
    let showError = false;

    const updatedEvent = {
      ...this.state.currentEvent,
      idGroup: this.state.currentUser.group.id
    };

    showError = !eventService.attributesIsNotNull(updatedEvent);
    this.setState({ showError });
    if (showError) {
      event.preventDefault();
      return;
    }
    this.setState({ loading: true });
    if (this.state.editMode) {
      //Si on modifie le signalement
      EventApi.updateEvent(updatedEvent).then((event) => {
        successToast(I18n.t('EditToastSuccess', { id: event.id }))
        this.setState({ loading: false });
        this.redirectTo({ pathname: `/evenement/${updatedEvent.id}/status`, state: { page: this.state.page } });
      }).catch(() => {
        errorToast(I18n.t('EditToastError', { id: event.id }))
        this.setState({ loading: false });
      });
    } else {
      //Si on créer le signalement
      EventApi.createEvent(updatedEvent).then((event) => {
        this.setState({ loading: false });
        //Si on ne duplique pas on retourne à l'accueil
        if (!duplicate) {
          successToast(I18n.t('SaveToastSuccess', { id: event.id }))
          this.redirectTo('/accueil');
        } else {
          successToast(I18n.t('DuplicateToastSuccess', { id: event.id }))
          //Si on duplique :
          // On garde que les infos : date - lieu - adresse - champs patrimoine - espace video surveillance
          const partialEvent = {
            ...updatedEvent,
            type: null,
            subtype: null,
            category: null,
            locationType: null,
            locationSubtype: null,
            recurrent: false,
            comment: null,
            victims: [],
            id: null
          }
          this.setState({ loading: false, currentEvent: partialEvent });
          window.scrollTo(0, 0)
        }
      }).catch(() => {
        if (duplicate) {
          errorToast(I18n.t('DuplicateToastError'))
        } else {
          errorToast(I18n.t('SaveToastError'))
        }
        this.setState({ loading: false });
      });
    }
  };

  redirectTo = (path) => {
    this.setState({ redirectPath: path });
  };

  render() {
    if (this.state.redirectPath) {
      return (<Redirect to={this.state.redirectPath} push />)
    }
    return (
      <div className="container-fluid">
        <Logo />
        <div className="container">
          <div className="row">
            <div className="title">
              {`${this.state.editMode ? I18n.t('ReportEditTitle') : I18n.t('ReportTitle')} ${this.state.currentEvent.id ? this.state.currentEvent.id : ''}`}
            </div>
          </div>
          {!this.state.editMode || !this.state.loading ?
            <Form onSubmit={this.onSubmit}>
              {this.renderGeneralInfos()}
              {this.renderEventType()}
              {this.renderEventSubType()}
              {this.renderEventCategory()}
              {this.renderEventPlace()}
              {this.renderEventSubPlace()}
              {this.renderEventAddress()}
              {this.renderEventComplementaryInfos()}
              {this.renderEventComment()}
              {this.renderFormButtons()}
            </Form>
            : <Loader displayMessage />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locations } = state.resourceReducer;
  const { victims } = state.resourceReducer;
  const { user } = state.userReducer;
  const { patrimonies } = state.resourceReducer;
  const { types } = state.resourceReducer;

  return {
    types,
    locations,
    victims,
    patrimonies,
    currentUser: user
  };
};

export default connect(mapStateToProps)(withRouter(Report));
