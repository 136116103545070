import React, { Component } from 'react';
import I18n from '../../resources/i18n/I18n';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class ModalEdition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    editLabel = () => {
        this.toggleModal();
        this.props.editLabel();
    };

    toggleModal = () => {
        this.props.toggleModal();
    };

    onChangeLabel = (e) => {
        this.props.modifyItemLabel(e.target.value)
    };

    render() {
        return (
            <div>
                <Modal show={this.props.showModal} onHide={this.toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.props.title ?
                                    this.props.title :
                                    I18n.t('EditConfirmTitle')
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{I18n.t('Label')}</Form.Label>
                            <Form.Control
                                defaultValue={this.props.item ? this.props.item.label : ''}
                                onChange={this.onChangeLabel} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-back" onClick={this.toggleModal}>
                            {I18n.t('ModalConfirmCancel')}
                        </Button>
                        <Button className="primary" onClick={this.editLabel}>
                            {I18n.t('ModalConfirmOk')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}


export default ModalEdition;
