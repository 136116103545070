import CustomButton from 'components/buttons/CustomButton';
import React from 'react';
import Image from 'react-bootstrap/Image';
import images from 'resources/assets/images';
import I18n from 'resources/i18n/I18n';
import './login.scss';
import { redirectToLogin } from 'services/keycloakService';
import SupportContact from 'components/SupportContact/SupportContact';

/**
 * Composant qui permet de gérer la pré homepage
 */
const Login = () => {
    return (
        <div className="col-md-6 col-10 container-fluid d-flex flex-column align-items-center">
            <Image className="logo" src={images.homeLogo} />
            <div className="d-flex flex-column align-items-center text-white w-100">
                <div className='d-flex flex-column align-items-center justify-content-between welcome w-100 mb-4 pt-2 pb-2 px-5'>
                    <h5 className='font-weight-bold'>{I18n.t('LoginMessageTitle')}</h5>
                    <span>{I18n.t('LoginMessageBody')}</span>
                </div>
                <CustomButton onClick={redirectToLogin} small primary title={I18n.t('LoginButton')} />
                <SupportContact className='mt-4' />
            </div>
        </div>
    );
}
export default Login;
