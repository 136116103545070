import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from '../../resources/i18n/I18n';
import 'moment/locale/fr';
import './filters.scss';
import './autocomplete.scss';
import FormControl from 'react-bootstrap/FormControl';
import Criteria from './Criteria';
import Form from "react-bootstrap/Form";

class GroupFilters extends Component {

    render() {
        return (
            <div className="d-flex w-100 flex-column align-items-center justify-content-between">
                <div className="w-100 header-criteria text-uppercase">
                    <span>{I18n.t('FiltersTitle')}</span>
                </div>
                <Criteria title={I18n.t('GroupCodeCriteria')}>
                    <FormControl
                        name='code'
                        value={this.props.filterList.code || ''}
                        onChange={(event) => this.props.handleChange(event.target.name, event.target.value)}
                    />
                </Criteria>
                <Criteria title={I18n.t('GroupNameCriteria')}>
                    <FormControl
                        name='name'
                        value={this.props.filterList.name || ''}
                        onChange={(event) => this.props.handleChange(event.target.name, event.target.value)}
                    />
                </Criteria>
                <Criteria title={I18n.t('GroupCommentCriteria')}>
                    <Form.Check
                        id='enableComment'
                        name='enableComment'
                        checked={this.props.filterList.enableComment || undefined}
                        onClick={(event) => {
                            this.props.handleChange(event.target.name, event.target.checked || undefined)
                        }}
                        label={I18n.t('CommentCriteria')}
                        type="checkbox"
                    />
                </Criteria>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        resources: state.resourceReducer
    };
};

export default connect(mapStateToProps)(GroupFilters);
