import React, { Component } from 'react';

class TotalNumber extends Component {
    render() {
        const { data, unit } = this.props;

        return (
            <div className="total-tile">
                <div className="total-number">{`${data.toLocaleString()}€`}</div>
                <div>{unit}</div>
            </div>
        )
    }
}

export default TotalNumber;