import React, { Component } from "react";
import { PulseLoader } from "react-spinners";

/**
 * Permet d'afficher un loader sur un composant + un overlay
 * WrapperLoader component
 * 
 */
class WrapperLoader extends Component {

    render() {
        const { loading, className, children, loadingElementsSize } = this.props;

        return (
            <div className='position-relative'>
                {loading && (
                    <div className={`overlay-wrapper ${className}`}>
                        <PulseLoader className="spinner position-absolute" color="white" size={loadingElementsSize}/>
                    </div>
                )}
                {children}
            </div>
        )
    }
}
export default WrapperLoader;