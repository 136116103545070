import React from 'react';
import Button from 'react-bootstrap/Button'
import images from '../../resources/assets/images';
import { redirectToLogin } from '../../services/keycloakService';
import I18n from 'resources/i18n/I18n';
import SupportContact from 'components/SupportContact/SupportContact';

export const LoginError = ({error}) => (
    <div className='h-100'>
        <div className="container-fluid w-75">
            <div className="row justify-content-center">
                <img className="logo" alt="ORFI" src={images.homeLogo} />
            </div>
            <div className="d-flex flex-column align-items-center my-2">
                <h3 className='text-center'>{error}</h3>
                <Button className="primary w-25 my-2" onClick={redirectToLogin}>{I18n.t('Retry')}</Button>
                <SupportContact className='mt-4' />
            </div>
        </div>
        <div className='footer big' />
    </div>
);
export default LoginError;
