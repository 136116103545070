import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import I18n from 'resources/i18n/I18n';
import Logo from '../logo/Logo';
import Title from '../typo/Title';
import ItemList from './ItemList';
import './configuration.scss';

const Configuration = () => {
    const currentUser = useSelector(state => state.userReducer.user);

    return (
        <div className="container-fluid">
            <Logo />
            <div className="container">
                <div className="row">
                    <Title text={I18n.t('ConfigurationTitle')} />
                </div>
                <div className="row">
                    <ItemList title={I18n.t('ConfigurationCategory')} path="/configuration/category" />
                    <ItemList title={I18n.t('ConfigurationPlace')} path="/configuration/location" />
                    <ItemList title={I18n.t('ConfigurationProcess')} path="/configuration/process" />
                    <ItemList title={I18n.t('ConfigurationComplaint')} path="/configuration/complaint" />
                    {currentUser.roles && currentUser.roles.indexOf('ORFI-ADMIN') > -1 &&
                        <ItemList title={I18n.t('ConfigurationGroup')} path="/configuration/group" />
                    }
                </div>
            </div>
        </div>
    )
}

export default withRouter(Configuration);