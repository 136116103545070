import React, { Component } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import WrapperLoader from '../loader/WrapperLoader';

class SemiPieChart extends Component {
    render() {
        const { title, subtitle, data, loading } = this.props;

        return (
            <WrapperLoader loading={loading}>
                <div className="dashboard-tile">
                    <div className="legend">
                        <div className="bold">{title}</div>
                        <div>{subtitle}</div>
                    </div>
                    <div className="pie-chart">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie startAngle={180} dataKey="count" endAngle={0} data={data} cy={180} outerRadius={80} label>
                                    {
                                        data.map((entry, index) => <Cell key={index} fill={entry.color} />)
                                    }
                                </Pie>
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </WrapperLoader>
        )
    }
}

export default SemiPieChart;