import React, { Component } from 'react';
import moment from 'moment';
import CustomTooltip from './CustomTooltip';
import { LineChart, Line, XAxis, ResponsiveContainer, Tooltip } from 'recharts';
import WrapperLoader from '../loader/WrapperLoader';

class EvolutionChart extends Component {
    formatXAxis = (label) => {
        return moment(label).format('DD-MMM');
    };

    render() {
        const { data, title, subtitle, loading } = this.props;

        return (
            <WrapperLoader loading={loading}>
                <div className="dashboard-tile">
                    <div className="legend">
                        <div className="bold text-capitalize">{title}</div>
                        <div>{subtitle}</div>
                    </div>
                    <div className="evolution-chart">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={data}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <XAxis dataKey="date"
                                    tick={{ fontSize: 12 }}
                                    tickFormatter={this.formatXAxis}
                                />
                                <Tooltip content={<CustomTooltip />} />
                                <Line type="monotone" dataKey="count" stroke="#254c8e" strokeWidth="5" dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </WrapperLoader>
        )
    }
}

export default EvolutionChart;