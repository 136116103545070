import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-datetime/css/react-datetime.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import './styles/global.scss';
import { store, persistor } from './store/configureStore';
import history from './utils/history';

ReactDOM.render(<Provider store={store}><PersistGate loading={null} persistor={persistor}><BrowserRouter history={history}><App /></BrowserRouter></PersistGate></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
