import React, { Component } from 'react';
import I18n from '../../resources/i18n/I18n';
import WrapperLoader from '../loader/WrapperLoader';

class KeyNumber extends Component {
    render() {
        const { data, loading } = this.props;
        return (
            <WrapperLoader loading={loading}>
                <div className="dashboard-tile progress-tile">
                    <div className="legend mb-3">
                        <div className="bold">{I18n.t('KeyNumbers')}</div>
                        <div>{I18n.t('ByYear')}</div>
                    </div>
                    <div>
                        <span className="number">{data.cumbersome}</span>
                        <span>{I18n.t('Cumbersome')}</span>
                    </div>
                    <div>
                        <span className="number">{data.aggression}</span>
                        <span>{I18n.t('Aggression')}</span>
                    </div>
                    <div>
                        <span className="number">{data.degradation}</span>
                        <span>{I18n.t('Degradation')}</span>
                    </div>
                </div>
            </WrapperLoader>
        )
    }
}

export default KeyNumber;