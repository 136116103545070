import CustomButton from 'components/buttons/CustomButton';
import React, { useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { addProcessSuccess, deleteProcessSuccess, editProcessSuccess, fetchProcessSuccess } from 'store/actions/resourceActions';
import I18n from '../../resources/i18n/I18n';
import ProcessApi from '../../services/processApi';
import LabelEdition from './LabelEdition';

/**
 * Édition des processus
 * @returns 
 */
const ProcessEdition = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState(null);
    const processes = useSelector(state => state.resourceReducer.processes);

    useEffect(() => {
        if (processes.length === 0) {
            ProcessApi.getProcessList().then((response) =>
                dispatch(fetchProcessSuccess(response))
            )
        }
    }, []);

    const goBack = () => {
        history.goBack();
    };

    const deleteProcess = (process) => {
        setError(null);
        ProcessApi.deleteProcess(process.id).then(() =>
            dispatch(deleteProcessSuccess(process))
        ).catch(() =>
            setError(I18n.t('ErrorDelete'))
        )
    };

    const editProcess = (process) => {
        return ProcessApi.editProcess(process).then(() =>
            dispatch(editProcessSuccess(process))
        )
    };

    const createProcess = (label) => {
        ProcessApi.addProcess({ label }).then((process) =>
            dispatch(addProcessSuccess(process))
        )
    };

    return (
        <div className="container-fluid">
            <LabelEdition
                items={processes}
                deleteLabel={deleteProcess}
                editLabel={editProcess}
                createLabel={createProcess}
                error={error}
            />
            <div className="row justify-content-center p-4">
                <CustomButton onClick={goBack} icon={<FontAwesome name="arrow-left" />} small secondary />
            </div>
        </div>
    )
}

export default withRouter(ProcessEdition);
