import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from '../../resources/i18n/I18n';
import TypeApi from '../../services/typeApi';
import { fetchTypesSuccess } from 'store/actions/resourceActions';
import CustomButton from 'components/buttons/CustomButton';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router-dom';
import LabelEditionTree from './LabelEditionTree';

class CategoryEdition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        if (this.props.resources.types.length === 0) {
            this.getTypes();
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    getTypes = () => {
        TypeApi.getTypeList().then((response) =>
            this.props.dispatch(fetchTypesSuccess(response))
        )
    };

    deleteCategory = (category) => {
        this.setState({ error: null });
        TypeApi.deleteCategory(category.id).then(() =>
            //Récupération des types mis à jour
            this.getTypes()
        ).catch(() =>
            this.setState({ error: I18n.t('ErrorDelete') })
        )
    };

    editCategory = (category) => {
        TypeApi.editCategory(category).then(() =>
            //Récupération des types mis à jour
            this.getTypes()
        )
    };

    createCategory = (item) => {
        TypeApi.addCategory(item.idParent, { label: item.label }).then(() =>
            //Récupération des types mis à jour
            this.getTypes()
        )
    };

    render() {
        const { types } = this.props.resources;
        const tree = [];
        //Construction du modèle pour qu'il soit compatible avec le composant LabelEditionTree
        types.map((item) => {
            return item.subtypes.map((s) => {
                const subtype = { ...s, subtypes: s.categories };
                return tree.push(subtype);
            });
        });

        return (
            <div className="container-fluid">
                <LabelEditionTree items={tree}
                    deleteLabel={this.deleteCategory}
                    editLabel={this.editCategory}
                    createLabel={this.createCategory}
                    error={this.state.error} />
                <div className="row justify-content-center p-4">
                    <CustomButton onClick={() => this.goBack()} icon={<FontAwesome name="arrow-left" />} small secondary />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        resources: state.resourceReducer
    };
};

export default connect(mapStateToProps)(withRouter(CategoryEdition));