import React, { Component } from 'react';
import Title from '../typo/Title';
import I18n from "../../resources/i18n/I18n";
import './help.scss';
import {connect} from "react-redux";
import Logo from '../logo/Logo';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class Help extends Component {
  render() {
    return (
        <div className="help">
            <Logo/>
            <div className="container">
                <div className="row">
                    <Title text={I18n.t('HelpTitle')}/>
                </div>
                <div className="row">
                    <ul>
                        <li><a href={`${process.env.PUBLIC_URL}/USH-ORFI-manuel-utilisation.pdf`}>{I18n.t('HelpDoc')}</a></li>
                        <li><Link to="/rgpd">{I18n.t('HelpRGPD')}</Link></li>
                    </ul>
                </div>
              <p className="">{this.props.user.roles && this.props.user.roles.indexOf('ORFI-GARDIEN') > -1 ?
                I18n.t('HelpKeeper') :
                <span>
                      {I18n.t('HelpDirector')}
                  <a href="mailto:orfi@union-habitat.org?subject=Support%20application%20ORFI">orfi@union-habitat.org</a>
                    </span>
              }</p>
            </div>
        </div>
    )
  }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user
    };
}


export default connect(mapStateToProps)(Help);
