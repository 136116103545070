/* eslint-disable global-require */
const images = {
  homeLogo: require('./home-logo.png'),
  homeBackground: require('./home-background.png'),
  iconEyeOff: require('./icon/icon-eye-off.png'),
  iconEyeOn: require('./icon/icon-eye-on.png'),
  iconHelp: require('./icon/icon-help.svg'),
  iconPinMap: require('./icon/icon-pin-map.png'),
  iconCheckboxEmpty: require('./check-box-empty-min.png'),
  iconCheckboxChecked: require('./check-box-min.png')
};

export default images;
