import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Redirect } from "react-router-dom";
import './itemList.scss';

class ItemList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectPath: null
        };
    }

    goTo = () => {
        this.setState({redirectPath: this.props.path})
    };

    render() {
        if (this.state.redirectPath) {
            if (this.props.openNewTab) {
                window.open(this.state.redirectPath, "_blank")
            } else {
                return (<Redirect to={this.state.redirectPath} push/>)
            }
        }

        return (
            <div className="configuration container d-flex" onClick={this.goTo}>
                { this.props.iconType && <span className={`d-flex align-items-center item-icon ${this.props.iconType}`}/>}
                <div className={`col-11 ${this.props.iconType ? 'row pl-4 ' : ''}`}>
                    <p className={`category-title ${this.props.iconType ? 'col-12 ' : ''} ${this.props.subtitle ? 'mt-3 mb-0' : 'mt-4 mb-4'}`}>
                        {this.props.title}
                    </p>
                    {this.props.subtitle && <p className={`description-card ${this.props.iconType ? 'col-12' : ''}`}>{this.props.subtitle}</p>}
                </div>
                <FontAwesome className="col-1 d-flex align-items-center justify-content-center" name="chevron-right" />
            </div>
        )
    }
}

export default ItemList;